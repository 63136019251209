import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setAlert } from "./utils/redux/reducer";
import Warning from "./img/warning.png";
import { makeStyles } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  root: {
    "& .MuiDialog-paper": {
      alignItems: "center",
      padding: "20px",
      maxWidth: "400px",
      "& .MuiDialogContent-root": {
        textAlign: "center",
        padding: "10px",
        "& .MuiDialogContentText-root": {
          fontSize: "15px",
          color: "rgba(0, 0, 0, 0.84)",
          lineHeight: "1.4",
        },
      },
      "& .MuiButton-root": {
        background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
        border: 0,
        borderRadius: 3,
        boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        color: "white",
        height: 30,
        padding: "0 30px",
      },
    },
  },
});

function LoadingDialog() {
  const classes = useStyles();

  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
      disableRestoreFocus
      TransitionComponent={Zoom}
    >
      <CircularProgress />
    </Dialog>
  );
}

export default LoadingDialog;
