import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import country2codes from "../../utils/regionCodes";
import Email from "../../components/form-input/Email";
import MobilePhone from "../../components/form-input/MobilePhone";
import Name from "../../components/form-input/Name";
import TaxId from "../../components/form-input/TaxId";
import useFormData from "./useFormData";
import CreditCardNumber from "../../components/form-input/CreditCardNumber";
import CreditCardExpTime from "../../components/form-input/CreditCardExpTime";
import Address from "../../components/form-input/Address/Address";
import HiddenForm from "../../components/form-input/Openpay-creditcard/HiddenForm";
import { setLoading, setAlert, setPayData, setTradeStatus } from "../../utils/redux/reducer";
import { googleTokenize, tokenize } from "../../utils/tokanization";
import { isProduction, setOpenpayAcoount } from "../../utils/envs";
import { issuer, hidedCardNo } from "../../utils/formats";
import request from "../../utils/axios";
import { sendMessage } from "../../utils/messages";
import { useNavigate, useLocation } from "react-router-dom";

function useMethodConfig() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [prepayId, tradeInfo, paymentInfo, loadedLibs] = useSelector((state) => [
    state.prepayId,
    state.tradeInfo,
    state.payInfo,
    state.loadedLibs,
  ]);
  const { method, channel } = tradeInfo || {};
  const appRegion = tradeInfo?.app_info?.region;
  const dispatch = useDispatch();
  const [form, setForm] = useFormData();

  // console.log("useMethodConfig form updated: ", form);

  const commonForms = {
    "form-1": (
      <>
        <div className="home--row">
          <Name form={form} setForm={setForm} />
          <TaxId form={form} setForm={setForm} />
        </div>
        <div className="home--row">
          <Email form={form} setForm={setForm} />
          <MobilePhone form={form} setForm={setForm} />
        </div>
      </>
    ),
    "form-2": (
      <>
        <div className="home--row">
          <Name form={form} setForm={setForm} />
        </div>
        <div className="home--row">
          <Email form={form} setForm={setForm} />
          <MobilePhone form={form} setForm={setForm} />
        </div>
      </>
    ),
  };

  const commonResponseHandlers = {
    replaceToCheckout: (res) => {
      // dispatch(setPayData(res.data.data));
      // dispatch(setTradeStatus({ id: "order" }));
      // history.replace(`/checkout?prepay_id=${prepayId}${returnUrlAsParam()}`);
    },
    replaceToResult: () => {
      // dispatch(setTradeStatus({ id: "process", query: true }));
      // history.replace(`/result?prepay_id=${prepayId}${returnUrlAsParam()}`);
    },
    replaceToWalletUrl: (res) => {
      res.data?.data?.wallet_url && window.location.replace(res.data?.data?.wallet_url);
    },
    submitPost: (res) => {
      setForm((prev) => ({ ...prev, submitPost: res.data?.data }));
    },
  };

  const commonRequestHandlers = {
    payRequest: function (reqData, responseHandler) {
      request
        .post(`/api/trade/pay?prepay_id=${prepayId}`, reqData)
        .then((res) => {
          if (res.data.code === "10000") {
            responseHandler(res);
          } else {
            sendMessage({ message: "error", data: res?.data?.sub_msg || res?.data?.msg });
            dispatch(
              setAlert({
                state: true,
                status: "fail",
                message: "failed",
              })
            );
          }
        })
        .catch((err) => {
          console.log("err: ", err);
          if (err && !err.response) {
            console.log("network error: ", err);
            sendMessage({ message: "error", data: "network error" });
            dispatch(
              setAlert({
                state: true,
                status: "warning",
                message: t("network_err"),
                callback: () => {
                  dispatch(setLoading(false));
                },
              })
            );
          } else {
            sendMessage({ message: "error", data: "other error" });
            dispatch(
              setAlert({
                state: true,
                status: "warning",
                message: "fail_try_agin",
                callback: () => {
                  dispatch(setLoading(false));
                },
              })
            );
          }
        });
    },
    commonData: {
      method: method,
      channel: channel,
      username: form.name.value?.trim(),
      email: form.email.value?.toLowerCase(),
      phone: form.phone.value?.replace(/[^\d]/g, ""),
      amount: paymentInfo?.pay_amount,
      currency: paymentInfo?.pay_currency,
      out_trade_no: tradeInfo?.out_trade_no,
      prev_url: document.referrer,
      pay_region: appRegion,
    },
    getCommonData: function ({ isAddressNeeded = false, extraData = {} }) {
      // console.log("commondata: ", this.commonData);
      const data = { ...this.commonData, ...extraData };
      // console.log("data : ", data);
      if (tradeInfo?.is_original_source_required) {
        data.original_source = form.source.value;
      }
      if (isAddressNeeded) {
        const billingAddress = {
          address: {
            postal_code: form.bill_postalCode.value,
            country: form.bill_country.value,
            country_code: country2codes.find((item) => item.country_name === form.bill_country.value)?.alpha2_code,
            state: form.bill_state.value,
            city: form.bill_city.value,
            street: form.bill_street.value,
            street_number: form.bill_no.value,
          },
        };
        data.billing = { ...billingAddress };
        data.shipping = form.isSameAddress
          ? { ...billingAddress }
          : {
              address: {
                postal_code: form.delivery_postalCode.value,
                country: form.delivery_country.value,
                country_code: country2codes.find((item) => item.country_name === form.delivery_country.value)
                  ?.alpha2_code,
                state: form.delivery_state.value,
                city: form.delivery_city.value,
                street: form.delivery_street.value,
                street_number: form.delivery_no.value,
              },
            };
      }
      return data;
    },
    requestWithCommonData: function ({ extraData = {}, isAddressNeeded = false, formArray, responseHandler }) {
      if (this.isAllValid({ formArray })) {
        this.payRequest(this.getCommonData({ extraData, isAddressNeeded }), responseHandler);
      } else {
        // dispatch(setAlert({ state: true, message: t("fill_all") }));
      }
    },
    isAllValid: function ({ formArray = [], setform = true, callback = () => {} }) {
      if (tradeInfo?.is_original_source_required) {
        formArray.push(form.source);
      }
      const formValidity = formArray?.reduce?.((result, item) => {
        const itemValidity = !setform
          ? item.valid && item.checked
          : typeof item?.validityFunction === "function"
          ? item?.validityFunction({
              form: form,
              setForm: setForm,
              region: appRegion,
              isGlobal: form.isGlobal,
              bank: channel?.data?.[0]?.bank,
            })
          : false;
        // console.log(item + " ---- " + itemValidity);
        return result && itemValidity;
      }, true);
      formValidity && callback();
      return formValidity;
    },
  };

  return {
    CreditCard: {
      title: t("wallet"),
      channels: {
        OpenPay: {
          channelList: [],
          channelConfig: {
            formArray: [form.name, form.cardNumber, form.expDate, form.cvc, form.phone, form.email],
            jsxForm: (
              <>
                <div className="home--row">
                  <Name form={form} setForm={setForm} creditcard={true} />
                  {/* <Installment form={form} setForm={setForm} /> */}
                </div>
                <div className="home--row">
                  <CreditCardNumber form={form} setForm={setForm} />
                  <CreditCardExpTime form={form} setForm={setForm} />
                </div>

                <div className="home--row">
                  <MobilePhone form={form} setForm={setForm} />
                  <Email form={form} setForm={setForm} />
                </div>
                <Address form={form} setForm={setForm} />
                <HiddenForm form={form} />
              </>
            ),
            handleRequest: function () {
              const that = this;
              if (loadedLibs["CreditCard-OpenPay"]) {
                commonRequestHandlers.isAllValid({
                  formArray: that.formArray,
                  callback: () => {
                    dispatch(setLoading(true));
                    googleTokenize()
                      .then((captchaToken) => {
                        setOpenpayAcoount(appRegion);
                        const deviceSessionId = window.OpenPay?.deviceData?.setup(
                          "payment-form",
                          "deviceIdHiddenFieldName"
                        );
                        tokenize(
                          prepayId,
                          issuer,
                          form,
                          (token) => {
                            // callback for success
                            commonRequestHandlers.requestWithCommonData({
                              isAddressNeeded: true,
                              responseHandler: that.handleResponse,
                              formArray: that.formArray,
                              extraData: {
                                extra_token: deviceSessionId,
                                token: token,
                                card_no: hidedCardNo(form.cardNumber.value),
                                fingerprint: window.black_box_tongdun,
                                forter_token_cookie: window.black_box_forter,
                                captcha: captchaToken,
                              },
                            });
                          },
                          (err) => {
                            // callback for fail
                            console.log(err);
                            dispatch(
                              setAlert({
                                state: true,
                                status: "warning",
                                message: t("fail_try_agin"),
                                callback: () => {
                                  dispatch(setLoading(false));
                                },
                              })
                            );
                          }
                        );
                      })
                      .catch((err) => {
                        console.log(`Google captcha error: ${err}`);
                        dispatch(
                          setAlert({
                            state: true,
                            status: "warning",
                            message: t("fail_try_agin"),
                            callback: () => {
                              dispatch(setLoading(false));
                            },
                          })
                        );
                      });
                  },
                });
              } else {
                console.log("OpenPay libs are not loaded successfully :(");
                dispatch(
                  setAlert({
                    state: true,
                    status: "warning",
                    message: t("fail_try_agin"),
                    callback: () => {
                      window.location.reload();
                    },
                  })
                );
              }
            },
            handleResponse: function (res) {
              // console.log("res:   ", res);
              if (appRegion === "MEX") {
                if (!isProduction()) {
                  sendMessage({ message: "redirect", data: "http://demo.gemini-tiger.cn/callback/success" });
                  dispatch(
                    setAlert({
                      state: true,
                      status: "success",
                      message: "redirecting",
                    })
                  );
                } else {
                  if (res.data?.data?.check_url) {
                    sendMessage({ message: "redirect", data: res.data?.data?.check_url });
                    dispatch(
                      setAlert({
                        state: true,
                        status: "success",
                        message: "redirecting",
                      })
                    );
                    // window.top.location.href = res.data?.data?.check_url;
                  }
                }
              }
            },
          },
        },
      },
    },
    PIX: {
      title: t("wallet"),
      channels: {
        commanConfig: {
          channelList: [],
          channelConfig: {
            formArray: [form.name, form.taxId, form.email, form.phone],
            jsxForm: (
              <>
                <div className="home--row">
                  <TaxId form={form} setForm={setForm} />
                  {form.name.show && <Name form={form} setForm={setForm} />}
                </div>
                <div className="home--row">
                  {form.email.show && <Email form={form} setForm={setForm} />}
                  {form.phone.show && <MobilePhone form={form} setForm={setForm} />}
                </div>
              </>
            ),
            handleRequest: function () {
              dispatch(setLoading(true));
              const that = this;
              commonRequestHandlers.requestWithCommonData({
                responseHandler: that.handleResponse,
                formArray: that.formArray,
                extraData: {
                  id: {
                    type: form.idType,
                    number: form.taxId.value.replace(/[-/.]/g, ""),
                  },
                },
              });
            },
            handleResponse: function (res) {
              dispatch(setLoading(false));
              dispatch(setPayData(res.data.data));
              dispatch(setTradeStatus({ id: "order" }));
              navigate("/checkout" + search, { replace: true });
            },
          },
        },
      },
    },
  };
}

export default useMethodConfig;
