import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import country2codes from "../../../utils/regionCodes";
import TextField from "@material-ui/core/TextField";
import Correct from "../../../img/correct.jpg";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  checkBillCountry,
  checkDeliveryCountry,
  checkBillState,
  checkDeliveryState,
  checkBillCity,
  checkDeliveryCity,
  checkBillStreet,
  checkDeliveryStreet,
  checkBillNo,
  checkDeliveryNo,
  checkBillPostalCode,
  checkDeliveryPostalCode,
} from "../../../utils/validations";
import { formatPostalCode } from "../../../utils/formats";
import Checkbox from "@material-ui/core/Checkbox";
import "./Address.scss";
import { useEffect } from "react";
import { setAlert } from "../../../utils/redux/reducer";
import { useDispatch } from "react-redux";
import { InputLabel } from "@material-ui/core";
import TextFieldCustom from "../TextFieldCustom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  checkbox: (props) => ({
    "&.Mui-checked": {
      color: props.button?.color || "#01a27e",
    },
  }),
});

// limit country list according to method-channel pair
const countryListConfig = {
  "Trustly-Emerchantpay": [
    "AT",
    "BE",
    "CZ",
    "DK",
    "EE",
    "FI",
    "DE",
    "LV",
    "LT",
    "NL",
    "NO",
    "PL",
    "SK",
    "ES",
    "SE",
    "GB",
  ],
  "Sofort-Emerchantpay": ["AT", "BE", "DE", "IT", "NL", "PL", "ES", "CH"],
  "Bancontact-Emerchantpay": ["BE"],
  "Giropay-Emerchantpay": ["DE"],
  "iDeal-Emerchantpay": ["NL"],
  "MyBank-Emerchantpay": ["IT"],
  "P24-Emerchantpay": ["PL"],
  "EPS-Emerchantpay": ["AT"],
};

function Address({ form, setForm, setFocusOnInput = () => {}, ...props }) {
  const { t } = useTranslation();
  const styles = useSelector((state) => state.styles);
  const classes = useStyles(styles);
  const [billCountryValue, setBillCountryValue] = useState("");
  const [deliveryCountryValue, setDeliveryCountryValue] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [countryOptions, setCountryOptions] = useState(country2codes.map((item) => item.country_name));

  useEffect(() => {
    setForm((prev) => {
      return {
        ...prev,
        isSameAddress: isChecked,
      };
    });
  }, [isChecked]);

  // default select predicted country
  useEffect(() => {
    if (!form.bill_country.checked && countryOptions?.includes?.(props.geoLocation?.country_name)) {
      setForm((prev) => {
        return {
          ...prev,
          bill_country: { ...prev.bill_country, value: props.geoLocation?.country_name ?? "" },
        };
      });
    }
  }, [props.geoLocation, countryOptions]);

  // country list limitation
  useEffect(() => {
    const currentConfig = countryListConfig[`${props.method}-${props.payChannel}`];
    if (currentConfig) {
      const newCountryList = country2codes.reduce((result, item) => {
        if (currentConfig?.includes?.(item.alpha2_code)) {
          return [...result, item.country_name];
        } else {
          return result;
        }
      }, []);
      setCountryOptions(newCountryList);
      setForm((prev) => {
        return {
          ...prev,
          bill_country: newCountryList.includes(prev.bill_country.value)
            ? { ...prev.bill_country }
            : {
                ...prev.bill_country,
                value: newCountryList.length === 1 ? newCountryList[0] : "",
                valid: true,
                checked: newCountryList.length === 1 ? true : false,
              },
          delivery_country: newCountryList.includes(prev.delivery_country.value)
            ? { ...prev.delivery_country }
            : {
                ...prev.delivery_country,
                value: newCountryList.length === 1 ? newCountryList[0] : "",
                valid: true,
                checked: newCountryList.length === 1 ? true : false,
              },
        };
      });
    } else {
      setCountryOptions(country2codes.map((item) => item.country_name));
    }
  }, [props.method, props.payChannel]);

  return (
    <div className="address" style={{ display: form.hideAddress ? "none" : "block" }}>
      <div className="address__billing">
        <p className="address__title">{t("address_billing")}</p>
        <div>
          <div className="address__row">
            <Autocomplete
              className="homeMobile__filling__content__input"
              value={form.bill_country.value}
              onChange={(event, newValue) => {
                setForm((prev) => {
                  return {
                    ...prev,
                    bill_country: { ...prev.bill_country, value: newValue ?? "" },
                  };
                });
              }}
              inputValue={billCountryValue}
              onInputChange={(event, newInputValue) => {
                setBillCountryValue(newInputValue);
              }}
              options={countryOptions}
              renderInput={(params) => (
                <TextFieldCustom
                  {...params}
                  fullWidth
                  label={t("country")}
                  variant="outlined"
                  error={!form.bill_country.valid}
                  helperText={!form.bill_country.valid && t("invalid_country")}
                  inputProps={{ ...params.inputProps, autocomplete: "new-password" }}
                />
              )}
              onFocus={() => {
                setFocusOnInput(true);
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onBlur={() => {
                checkBillCountry({ form: form, setForm: setForm });
              }}
            />
            <div>
              <TextFieldCustom
                fullWidth
                onFocus={() => {
                  setFocusOnInput(true);
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                inputProps={{ autocomplete: "new-password" }}
                error={!form.bill_state.valid}
                helperText={!form.bill_state.valid && t("invalid_state")}
                className="homeMobile__filling__content__input"
                label={t("state")}
                InputLabelProps={{
                  variant: "standard",
                }}
                variant="outlined"
                value={form.bill_state.value}
                onChange={(event) => {
                  var format = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`·～！¥（）—】【」「、｜；：’‘“”？》。《，]+/g;
                  var value = event.target.value;
                  value = value.replace(format, "");
                  if (value?.length > form.bill_state.max) {
                    value = value.slice(0, form.bill_state.max);
                  }
                  setForm((prev) => {
                    return {
                      ...prev,
                      bill_state: { ...prev.bill_state, value: value },
                    };
                  });
                }}
                onBlur={() => {
                  checkBillState({ form: form, setForm: setForm });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={Correct}
                        alt=""
                        style={{
                          display: form.bill_state.valid && form.bill_state.checked ? "block" : "none",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className="address__row">
            <div>
              <TextFieldCustom
                fullWidth
                onFocus={() => {
                  setFocusOnInput(true);
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                inputProps={{ autocomplete: "new-password" }}
                error={!form.bill_city.valid}
                helperText={!form.bill_city.valid && t("invalid_city")}
                className="homeMobile__filling__content__input"
                label={t("city")}
                variant="outlined"
                value={form.bill_city.value}
                onChange={(event) => {
                  var format = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`·～！¥（）—】【」「、｜；：’‘“”？》。《，]+/g;
                  var value = event.target.value;
                  value = value.replace(format, "");
                  if (value?.length > form.bill_city.max) {
                    value = value.slice(0, form.bill_city.max);
                  }
                  setForm((prev) => {
                    return {
                      ...prev,
                      bill_city: { ...prev.bill_city, value: value },
                    };
                  });
                }}
                onBlur={() => {
                  checkBillCity({ form: form, setForm: setForm });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={Correct}
                        alt=""
                        style={{
                          display: form.bill_city.valid && form.bill_city.checked ? "block" : "none",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <TextFieldCustom
                fullWidth
                onFocus={() => {
                  setFocusOnInput(true);
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                inputProps={{ autocomplete: "new-password", "ga-data": "346" }}
                error={!form.bill_street.valid}
                helperText={!form.bill_street.valid && t("invalid_street")}
                className="homeMobile__filling__content__input"
                label={t("street")}
                variant="outlined"
                value={form.bill_street.value}
                onChange={(event) => {
                  var value = event.target.value;
                  if (value?.length > form.bill_street.max) {
                    value = value.slice(0, form.bill_street.max);
                  }
                  setForm((prev) => {
                    return {
                      ...prev,
                      bill_street: { ...prev.bill_street, value: value },
                    };
                  });
                }}
                onBlur={() => {
                  checkBillStreet({ form: form, setForm: setForm });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={Correct}
                        alt=""
                        style={{
                          display: form.bill_street.valid && form.bill_street.checked ? "block" : "none",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className="address__row">
            <div>
              <TextFieldCustom
                fullWidth
                onFocus={() => {
                  setFocusOnInput(true);
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                inputProps={{ autocomplete: "new-password", "ga-data": "347" }}
                error={!form.bill_no.valid}
                helperText={!form.bill_no.valid && t("invalid_number")}
                className="homeMobile__filling__content__input"
                label={t("number")}
                variant="outlined"
                value={form.bill_no.value}
                onChange={(event) => {
                  // eslint-disable-next-line no-useless-escape
                  var format = /[^\d-\./]+/g;
                  var value = event.target.value;
                  value = value.replace(format, "");
                  if (value?.length > form.bill_no.max) {
                    value = value.slice(0, form.bill_no.max);
                  }
                  setForm((prev) => {
                    return {
                      ...prev,
                      bill_no: { ...prev.bill_no, value: value },
                    };
                  });
                }}
                onBlur={() => {
                  checkBillNo({ form: form, setForm: setForm });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={Correct}
                        alt=""
                        style={{
                          display: form.bill_no.valid && form.bill_no.checked ? "block" : "none",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <TextFieldCustom
                fullWidth
                inputProps={{ autocomplete: "new-password", "ga-data": "343" }}
                onFocus={(event) => {
                  event.preventDefault();
                  setFocusOnInput(true);
                  if (!form.bill_country.value) {
                    props.setAlert({ state: true, status: "warning", message: "select_country" });
                  }
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                error={!form.bill_postalCode.valid}
                helperText={!form.bill_postalCode.valid && t("invalid_postal_code")}
                className="homeMobile__filling__content__input"
                label={t("postal_code")}
                variant="outlined"
                value={form.bill_postalCode.value}
                onChange={(event) => {
                  let value = event.target.value;
                  value = formatPostalCode(value, props.tradeInfo?.app_info?.region, form.bill_country.value, true);
                  setForm((prev) => {
                    return {
                      ...prev,
                      bill_postalCode: { ...prev.bill_postalCode, value: value },
                    };
                  });
                }}
                onBlur={() => {
                  checkBillPostalCode({ form: form, setForm: setForm });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={Correct}
                        alt=""
                        style={{
                          display: form.bill_postalCode.valid && form.bill_postalCode.checked ? "block" : "none",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="address__delivery">
        <p className="address__title">{t("address_delivery")}</p>
        <div className="address__checkbox">
          <Checkbox
            className={classes.checkbox}
            size="small"
            checked={isChecked}
            onClick={() => {
              setIsChecked((prev) => !prev);
            }}
          />
          <p
            onClick={() => {
              setIsChecked((prev) => !prev);
            }}
          >
            {t("address_same")}
          </p>
        </div>
        <div style={{ display: isChecked ? "none" : "block" }}>
          <div className="address__row">
            <Autocomplete
              className="homeMobile__filling__content__input"
              value={form.delivery_country.value}
              onChange={(event, newValue) => {
                setForm((prev) => {
                  return {
                    ...prev,
                    delivery_country: { ...prev.delivery_country, value: newValue ?? "" },
                  };
                });
              }}
              inputValue={deliveryCountryValue}
              onInputChange={(event, newInputValue) => {
                setDeliveryCountryValue(newInputValue);
              }}
              options={countryOptions}
              renderInput={(params) => (
                <div>
                  <TextFieldCustom
                    {...params}
                    label={t("country")}
                    fullWidth
                    variant="outlined"
                    error={!form.delivery_country.valid}
                    helperText={!form.delivery_country.valid && t("invalid_country")}
                    inputProps={{ ...params.inputProps, autocomplete: "new-password" }}
                  />
                </div>
              )}
              onFocus={() => {
                setFocusOnInput(true);
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onBlur={() => {
                checkDeliveryCountry({ form: form, setForm: setForm });
              }}
            />
            <div>
              <TextFieldCustom
                fullWidth
                onFocus={() => {
                  setFocusOnInput(true);
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                inputProps={{ autocomplete: "new-password" }}
                error={!form.delivery_state.valid}
                helperText={!form.delivery_state.valid && t("invalid_state")}
                className="homeMobile__filling__content__input"
                label={t("state")}
                variant="outlined"
                value={form.delivery_state.value}
                onChange={(event) => {
                  var format = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`·～！¥（）—】【」「、｜；：’‘“”？》。《，]+/g;
                  var value = event.target.value;
                  value = value.replace(format, "");
                  if (value?.length > form.delivery_state.max) {
                    value = value.slice(0, form.delivery_state.max);
                  }
                  setForm((prev) => {
                    return {
                      ...prev,
                      delivery_state: { ...prev.delivery_state, value: value },
                    };
                  });
                }}
                onBlur={() => {
                  checkDeliveryState({ form: form, setForm: setForm });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={Correct}
                        alt=""
                        style={{
                          display: form.delivery_state.valid && form.delivery_state.checked ? "block" : "none",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className="address__row">
            <div>
              <TextFieldCustom
                fullWidth
                onFocus={() => {
                  setFocusOnInput(true);
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                inputProps={{ autocomplete: "new-password" }}
                error={!form.delivery_city.valid}
                helperText={!form.delivery_city.valid && t("invalid_city")}
                className="homeMobile__filling__content__input"
                label={t("city")}
                variant="outlined"
                value={form.delivery_city.value}
                onChange={(event) => {
                  var format = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`·～！¥（）—】【」「、｜；：’‘“”？》。《，]+/g;
                  var value = event.target.value;
                  value = value.replace(format, "");
                  if (value?.length > form.delivery_city.max) {
                    value = value.slice(0, form.delivery_city.max);
                  }
                  setForm((prev) => {
                    return {
                      ...prev,
                      delivery_city: { ...prev.delivery_city, value: value },
                    };
                  });
                }}
                onBlur={() => {
                  checkDeliveryCity({ form: form, setForm: setForm });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={Correct}
                        alt=""
                        style={{
                          display: form.delivery_city.valid && form.delivery_city.checked ? "block" : "none",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div>
              <TextFieldCustom
                fullWidth
                onFocus={() => {
                  setFocusOnInput(true);
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                inputProps={{ autocomplete: "new-password", "ga-data": "346" }}
                error={!form.delivery_street.valid}
                helperText={!form.delivery_street.valid && t("invalid_street")}
                className="homeMobile__filling__content__input"
                label={t("street")}
                variant="outlined"
                value={form.delivery_street.value}
                onChange={(event) => {
                  var value = event.target.value;
                  if (value?.length > form.delivery_street.max) {
                    value = value.slice(0, form.delivery_street.max);
                  }
                  setForm((prev) => {
                    return {
                      ...prev,
                      delivery_street: { ...prev.delivery_street, value: value },
                    };
                  });
                }}
                onBlur={() => {
                  checkDeliveryStreet({ form: form, setForm: setForm });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={Correct}
                        alt=""
                        style={{
                          display: form.delivery_street.valid && form.delivery_street.checked ? "block" : "none",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className="address__row">
            <div>
              <TextFieldCustom
                fullWidth
                onFocus={() => {
                  setFocusOnInput(true);
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                inputProps={{ autocomplete: "new-password", "ga-data": "347" }}
                error={!form.delivery_no.valid}
                helperText={!form.delivery_no.valid && t("invalid_number")}
                className="homeMobile__filling__content__input"
                label={t("number")}
                variant="outlined"
                value={form.delivery_no.value}
                onChange={(event) => {
                  // eslint-disable-next-line no-useless-escape
                  var format = /[^\d-\./]+/g;
                  var value = event.target.value;
                  value = value.replace(format, "");
                  if (value?.length > form.delivery_no.max) {
                    value = value.slice(0, form.delivery_no.max);
                  }
                  setForm((prev) => {
                    return {
                      ...prev,
                      delivery_no: { ...prev.delivery_no, value: value },
                    };
                  });
                }}
                onBlur={() => {
                  checkDeliveryNo({ form: form, setForm: setForm });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={Correct}
                        alt=""
                        style={{
                          display: form.delivery_no.valid && form.delivery_no.checked ? "block" : "none",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div>
              <TextFieldCustom
                fullWidth
                inputProps={{ autocomplete: "new-password", "ga-data": "343" }}
                onFocus={() => {
                  setFocusOnInput(true);
                  if (!form.delivery_country.value) {
                    props.setAlert({ state: true, status: "warning", message: "select_country" });
                  }
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                error={!form.delivery_postalCode.valid}
                helperText={!form.delivery_postalCode.valid && t("invalid_postal_code")}
                className="homeMobile__filling__content__input"
                label={t("postal_code")}
                variant="outlined"
                value={form.delivery_postalCode.value}
                onChange={(event) => {
                  let value = event.target.value;
                  value = formatPostalCode(value, props.tradeInfo?.app_info?.region, form.delivery_country.value, true);
                  setForm((prev) => {
                    return {
                      ...prev,
                      delivery_postalCode: { ...prev.delivery_postalCode, value: value },
                    };
                  });
                }}
                onBlur={() => {
                  checkDeliveryPostalCode({ form: form, setForm: setForm });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={Correct}
                        alt=""
                        style={{
                          display:
                            form.delivery_postalCode.valid && form.delivery_postalCode.checked ? "block" : "none",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    geoLocation: state.geoLocation,
    method: state.method,
    payChannel: state.payChannel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { setAlert: (item) => dispatch(setAlert(item)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);
