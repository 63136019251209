export const sendMessage = ({ message, data }) => {
  if (message === "error") {
    window.parent.postMessage(`pagsmile.error|${data || "something went wrong!"}`, "*");
  } else if (message === "frameSize") {
    window.parent.postMessage(`pagsmile.frameResized|${data}`, "*");
  } else if (message === "process") {
    window.parent.postMessage("pagsmile.paymentInProcess|", "*");
  } else if (message === "success") {
    window.parent.postMessage("pagsmile.paymentSuccess|", "*");
  } else if (message === "redirect") {
    window.parent.postMessage(`pagsmile.redirectUrl|${data}`, "*");
  }
};
