import { createAction, createReducer, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../axios";
import { loadLibs } from "../loadLibs";
import getTradeStatus from "../../utils/tradeStatusCode";
import { sendMessage } from "../messages";
import { checkIsEmailValid, checkIsNameValid, checkIsPhoneValid, validateTaxId } from "../validations";
import countryList from "../regionCodes";
import { formatPhone } from "../formats";
import { checkoutConfig } from "../configurations";

export const setTradeInfo = createAction("setTradeInfo");
export const setMethod = createAction("setMethod");
export const setTradeStatus = createAction("setTradeStatus");
export const setPayChannel = createAction("setPayChannel");
export const setPayData = createAction("setPayData");
export const setPayInfo = createAction("setPayInfo");
export const setLoadedLibs = createAction("setLoadedLibs");
export const setAlert = createAction("setAlert");
export const setLoading = createAction("setLoading");
export const setAppLoading = createAction("setAppLoading");
export const setPrepayId = createAction("setPrepayId");
export const setStyles = createAction("setStyles");

export const fetchTradeInfo = createAsyncThunk("fetchTradeInfo", (prepayId, thunkAPI) => {
  return request.post(`/api/trade/query?prepay_id=${prepayId}`).then((res) => {
    if (res?.data?.trade_no) {
      const tradeStatus = getTradeStatus(res.data?.trade_status);
      thunkAPI.dispatch(setTradeStatus(tradeStatus));
      let channel = "";
      const { method } = res.data;
      thunkAPI.dispatch(setMethod(method));
      let channelInfoList = Array.isArray(res?.data?.channel_info_list) ? res?.data?.channel_info_list : [];
      channel = channelInfoList.find((item) => item.method === method)?.channel;
      thunkAPI.dispatch(setPayChannel(channel));

      if (tradeStatus.id === "trade") {
        const region = res.data?.app_info?.region;
        thunkAPI.dispatch(fetchPayInfo({ prepayId, method, channel }));
        let excludedLibs = {};
        loadLibs({
          channels: channelInfoList,
          region: region,
          setLibs: (item) => {
            thunkAPI.dispatch(setLoadedLibs(item));
          },
          excludedLibs: excludedLibs,
          prepayId: prepayId,
          isMultiRegion: res.data?.app_info?.global,
        });
      } else if (tradeStatus.id === "success") {
        sendMessage({ message: "success" });
        thunkAPI.dispatch(
          setAlert({
            state: true,
            status: "success",
            message: "success",
          })
        );
      } else if (tradeStatus.id === "process") {
        const checkoutPageAuth = checkoutConfig.includes(`${method}-${channel}`);
        if (checkoutPageAuth) {
          thunkAPI.dispatch(setAppLoading(false));
        } else {
          sendMessage({ message: "process" });
          thunkAPI.dispatch(
            setAlert({
              state: true,
              status: "process",
              message: "processing",
            })
          );
        }
      } else {
        // failed : fail, other
        sendMessage({ message: "error", data: res?.data?.sub_msg || res?.data?.msg });
        thunkAPI.dispatch(
          setAlert({
            state: true,
            status: "fail",
            message: "failed",
          })
        );
      }

      return { ...res.data, channel: channel };
    } else if (res.data.code === "40002" && res.data?.sub_code === "timed-out") {
      thunkAPI.dispatch(setAlert({ state: true, status: "error", message: "order_expired" }));
      window.parent.postMessage(`pagsmile.orderExpired`, "*");
    } else {
      return thunkAPI.rejectWithValue(res.data);
    }
  });
});

export const fetchPayInfo = createAsyncThunk("fetchPayInfo", ({ prepayId, method, channel }, thunkAPI) => {
  return request
    .post(`/api/trade/change-method?prepay_id=${prepayId}&method=${method}&channel=${channel}`)
    .then((res) => {
      if (res?.data?.code === "10000") {
        return res.data?.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    });
});

const queriedIDs = [];
export const getInfoById = createAsyncThunk("getInfoById", ({ setForm, data: { type = "", id = "" } }, thunkAPI) => {
  const { prepayId, tradeInfo } = thunkAPI.getState();
  const { method } = tradeInfo;
  id = id.replace(/[^a-zA-Z0-9]/g, "");
  if (method === "PIX" && validateTaxId(id, "BRA", type) && !queriedIDs.includes(id)) {
    setForm((prev) => {
      return {
        ...prev,
        email: { ...prev.email, show: true },
        phone: { ...prev.phone, show: true },
        name: { ...prev.name, show: true },
      };
    });
    request.post(`/api/trade/cpf?prepay_id=${prepayId}`, { type: type, number: id }).then((res) => {
      const data = res?.data?.data;
      if (res?.data?.code === "10000") {
        queriedIDs.push(id);
        const emailValidity = !tradeInfo?.user?.email && checkIsEmailValid(data.email);
        const phoneValidity = !checkIsPhoneValid(tradeInfo?.user?.phone, "BRA") && checkIsPhoneValid(data.phone, "BRA");
        const nameValidity = !tradeInfo?.user?.username && checkIsNameValid(data.name || "");

        setForm((prev) => {
          return {
            ...prev,
            name: nameValidity ? { ...prev.name, value: data.name, valid: true, checked: true } : { ...prev.name },
            email: emailValidity ? { ...prev.email, value: data.email, valid: true, checked: true } : { ...prev.email },
            phone: phoneValidity
              ? {
                  ...prev.phone,
                  countryObj: countryList.find((item) => item?.alpha3_code === "BRA"),
                  value: formatPhone(data.phone, "BR", true),
                  valid: true,
                  checked: true,
                }
              : { ...prev.phone },
          };
        });
      }
    });
  }
});

const initialState = {
  method: "",
  payChannel: "",
  appLoading: true,
  tradeStatus: {},
  loading: false,
  prepayId: "",
  tradeInfo: "",
  payInfo: "",
  payData: "",
  loadedLibs: {},
  styles: {},
  alert: { state: false, status: "", message: "", title: "", callback: null },
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setTradeInfo, (state, action) => {
      state.tradeInfo = action.payload;
    })
    .addCase(setMethod, (state, action) => {
      state.method = action.payload;
    })
    .addCase(setTradeStatus, (state, action) => {
      state.tradeStatus = action.payload;
    })
    .addCase(setPayChannel, (state, action) => {
      state.payChannel = action.payload;
    })
    .addCase(setPayInfo, (state, action) => {
      state.payInfo = action.payload;
    })
    .addCase(setPayData, (state, action) => {
      state.payData = action.payload;
    })
    .addCase(setLoadedLibs, (state, action) => {
      state.loadedLibs = action.payload;
    })
    .addCase(setAlert, (state, action) => {
      state.loading = false;
      state.alert = action.payload;
    })
    .addCase(setStyles, (state, action) => {
      state.styles = action.payload;
    })
    .addCase(setLoading, (state, action) => {
      state.loading = action.payload;
    })
    .addCase(setAppLoading, (state, action) => {
      state.appLoading = action.payload;
    })
    .addCase(setPrepayId, (state, action) => {
      state.prepayId = action.payload;
    })
    .addCase(fetchTradeInfo.fulfilled, (state, action) => {
      state.tradeInfo = action.payload;
      state.payData = action.payload?.payment_response;
    })
    .addCase(fetchTradeInfo.pending, (state, action) => {})
    .addCase(fetchTradeInfo.rejected, (state, action) => {
      sendMessage({ message: "error", data: action?.sub_msg || action?.msg });
    })
    .addCase(fetchPayInfo.fulfilled, (state, action) => {
      state.appLoading = false;
      state.payInfo = action.payload;
    })
    .addCase(fetchPayInfo.rejected, (state, action) => {
      sendMessage({ message: "error", data: action?.sub_msg || action?.msg });
    });
});

export default reducer;
