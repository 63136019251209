import React from "react";
import { InputLabel } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  label: (props) => ({
    padding: "5px 0",
    font: props.label?.font,
    color: props.label?.color,
    "& .MuiFormLabel-asterisk": {
      color: "#f44336",
    },
  }),
  input: (props) => ({
    height: props.input?.height,
    font: props.input?.font,
    color: props.input?.color,
  }),
});

function TextFieldCustom({ label, ...props }) {
  const styles = useSelector((state) => state.styles);
  const classes = useStyles(styles);
  return (
    <div>
      <InputLabel required className={classes.label}>
        {label}
      </InputLabel>
      <TextField {...props} InputProps={{ ...props.InputProps, className: classes.input }} />
    </div>
  );
}

export default TextFieldCustom;
