import { useEffect } from "react";
import "./App.css";
import getParameterByName from "./utils/queryUrl";
import { useDispatch } from "react-redux";
import { fetchTradeInfo, setPrepayId, setStyles } from "./utils/redux/reducer";
import Home from "./pages/Home/Home";
import AlertDialog from "./AlertDialog";
import { useSelector } from "react-redux";
import LoadingDialog from "./LoadingDialog";
import { useTranslation } from "react-i18next";
import { sendMessage } from "./utils/messages";
import { Outlet } from "react-router-dom";
import RequireAuth from "./pages/RequireAuth";

function App() {
  const { t, i18n } = useTranslation();
  const { loading, appLoading } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    //window.onload = () => sendPostMessage();
    //window.onresize = () => sendPostMessage();
    // create an Observer instance
    const resizeObserver = new ResizeObserver((entries) =>
      sendMessage({ message: "frameSize", data: entries[0].target.clientHeight })
    );

    // start observing a DOM node
    resizeObserver.observe(document.body);

    const prepayId = getParameterByName("prepay_id");
    // styles for the iframe
    const styles = {
      label: {
        font: getParameterByName("label_font") || '14px "Open Sans-SemiBold", "Open Sans"',
        color: getParameterByName("label_color") || "#323233",
      },
      input: {
        font: getParameterByName("input_font"),
        color: getParameterByName("input_color"),
        height: getParameterByName("input_height") ? getParameterByName("input_height") + "px" : 50,
      },
      button: {
        color: getParameterByName("button_color") || "#025fe6",
        font: getParameterByName("button_font"),
      },
    };
    dispatch(setStyles(styles));

    const lang = getParameterByName("lang");
    if (["en", "es", "pt"].includes(lang)) {
      i18n.changeLanguage(lang);
    }
    dispatch(setPrepayId(prepayId));
    if (prepayId) {
      dispatch(fetchTradeInfo(prepayId));
    }
  }, []);

  return (
    <div className="App">
      <AlertDialog />
      {loading && <LoadingDialog />}
      {appLoading ? (
        <LoadingDialog />
      ) : (
        <RequireAuth>
          <Outlet />
        </RequireAuth>
      )}
    </div>
  );
}

export default App;
