import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import InputAdornment from "@material-ui/core/InputAdornment";
import Correct from "../../img/correct.jpg";
import { checkName } from "../../utils/validations";
import { useSelector } from "react-redux";
import TextFieldCustom from "./TextFieldCustom";

function Name({ form, setForm, setFocusOnInput = () => {}, creditcard = false, mobile }) {
  const { t } = useTranslation();
  const [tradeInfo] = useSelector((state) => [state.tradeInfo]);
  useEffect(() => {
    if (!form.name.checked) {
      let name = tradeInfo?.user?.username ? tradeInfo?.user?.username : localStorage?.getItem("__frm_name");

      if (name) {
        setForm((prev) => {
          return {
            ...prev,
            name: { ...prev.name, value: name },
          };
        });
        checkName({ form: form, setForm: setForm, data: name });
      }
    }
  }, []);

  return (
    <div style={{ display: form.name.hidden ? "none" : "block" }}>
      {/* <InputLabel shrink required style={{ padding: "5px 0" }}>
        {creditcard ? t("cardholder_name") : t("name")}
      </InputLabel> */}
      <TextFieldCustom
        fullWidth
        id={mobile ? "inputform-name-mob" : "inputform-name"}
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        inputProps={{ autocomplete: "new-password", "ga-data": "336" }}
        InputLabelProps={{
          required: true,
          // shrink: true,
          // disableAnimation: true,
        }}
        error={!form.name.valid}
        helperText={!form.name.valid && t("invalid_name")}
        className="homeMobile__filling__content__input"
        label={creditcard ? t("cardholder_name") : t("name")}
        variant="outlined"
        value={form.name.value}
        onChange={(event) => {
          var format = /[0-9!@#$%^&*()_+\=\[\]{};:"\\|,<>\/?`·～！¥（）—】【」「、｜；：’‘“”？》。《，]+/g;
          var value = event.target.value;
          value = value.replace(format, "");
          if (value?.length > form.name.max) {
            value = value.slice(0, form.name.max);
          }
          setForm((prev) => {
            return {
              ...prev,
              name: { ...prev.name, value: value },
            };
          });
        }}
        onBlur={() => {
          checkName({ form: form, setForm: setForm });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <>
                <img
                  src={Correct}
                  alt=""
                  style={{
                    display: form.name.valid && form.name.checked ? "block" : "none",
                    marginRight: "5px",
                  }}
                />
              </>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default Name;
