// export const channelConfig = (method, channel) => {
//   let config = [
//     "Cash-SafetyPay",
//     "Cash-PagoEfectivo",
//     "BankTransfer-PagoEfectivo",
//     "BankTransfer-SafetyPay",
//     "Khipu",
//     "Wallet",
//     "DepositExpress",
//     "DirectDebit",
//     "PagoEfectivo",
//   ]; // methods that have channels to be choosen
//   return config.indexOf(method) >= 0 || config.indexOf(method + "-" + channel) >= 0;
// };

// export const walletChannels = {
//   MEX: { Todito: ToditoCash },
//   BRA: {
//     Paypal: Paypal,
//     PaypalXCG: Paypal,
//     AME: Ame,
//     PicPay: Picpay,
//     Eprepag: Eprepag,
//     MOL: MOL,
//     Paysafecard: Paysafecard,
//     Skrill: Skrill,
//     AstroPay: AstroPay,
//   },
//   CHL: { Mach: Mach, Vita: Vita, Chek: Chek, Fpay: Fpay },
//   PER: { Fpay: Fpay },
//   HKG: { AlipayHK: Alipayhk },
//   PHL: {
//     GCash: GcashAlipay,
//     GcashXendit: Gcash,
//     Paymaya: Paymaya,
//     BPI: BpiAc,
//   },
//   KOR: {
//     KakaoPayAlipay: KakaoPayAlipay,
//     TOSS: Toss,
//     PAYCO: Payco,
//     SamsungPay: SamsungPay,
//     KakaoPay: KakaoPay,
//     NaverPay: NaverPay,
//   },
//   IDN: { DANA: Dana },
//   MYS: { TNG: Tng, BOOST: BoostAlipay },
//   THA: {
//     TrueMoney: Truemoney,
//     RabbitLinePay: RabbitLineAc,
//   },
//   COL: {
//     TPaga: Tpaga,
//     Daviplata: Daviplata,
//     Rappipay: Rapipay,
//     Dale: Dale,
//     Movii: Movii,
//     Nequi: Nequi,
//     ClaroPay: ClaroPay,
//   },
//   CHN: { AlipayCN: Alipay },
//   SGP: { GrabPay: Grab, PayNow: Paynow },
//   SAU: { MADA: Mada },
//   KWT: { KNet: Knet },
//   RUS: { YooMoney: Yoomoney, QIWI: KiwiWallet },
//   EGY: { Fawry: Fawry },
// };

// export const depositExpressChannels = [
//   {
//     data: [{ logo: Itau, bank: "itau" }],
//     key: uuid(),
//     "ga-data": "7",
//   },
//   {
//     data: [{ logo: Santander, bank: "santander" }],
//     key: uuid(),
//     "ga-data": "8",
//   },
//   // {
//   //   data: [{ logo: BancoDoBrasil, bank: "banco-do-brasil" }],
//   //   key: uuid(),
//   //   "ga-data": "9",
//   // },
//   {
//     data: [{ logo: Bradesco, bank: "bradesco" }],
//     key: uuid(),
//     "ga-data": "10",
//   },
//   {
//     data: [{ logo: Caixa, bank: "caixa" }],
//     key: uuid(),
//     "ga-data": "11",
//   },
// ];

// export const localPaymentBankTransferChannels = [
//   {
//     data: [{ logo: Itau, bank: "itau" }],
//     key: uuid(),
//   },
//   {
//     data: [{ logo: Brou, bank: "brou" }],
//     key: uuid(),
//   },
// ];

// export const directDebitChannels = [
//   {
//     data: [{ logo: Ubp, bank: "BA_UBP" }],
//     key: uuid(),
//     "ga-data": "223",
//   },
//   {
//     data: [{ logo: Bpi, bank: "BA_BPI" }],
//     key: uuid(),
//     "ga-data": "222",
//   },
// ];

// export const pagoEfectivoChannels = [
//   {
//     data: [
//       { logo: YapeWallet },
//       { logo: PlinWallet },
//       { logo: PeBanbifWallet },
//       { logo: PeBbvaWallet },
//       { logo: InterbankWallet },
//       { logo: LigoWallet },
//       { logo: ScotiabankWallet },
//       { logo: TunkiWallet },
//       { logo: PeScotia },
//       { logo: PePambo },
//       { logo: PeWu },
//       { logo: PeBanbif },
//       { logo: PeBbva },
//       { logo: PeBcp },
//       { logo: PeCaja },
//       { logo: PeDisashop },
//       { logo: PeInterbank },
//       { logo: PeKasnet },
//       { logo: PeCellPower },
//       { logo: PeGanaste },
//       { logo: PeMoneyGram },
//       { logo: PeNiuBiz },
//       { logo: PePichinca },
//       { logo: PeRedDigit },
//     ],
//     key: uuid(),
//   },
// ];

// export const pagoEfectivoBankChannels = [
//   {
//     data: [
//       { logo: PeBbva },
//       { logo: PeBcp },
//       { logo: PeInterbank },
//       { logo: PeCaja },
//       { logo: PeScotia },
//       { logo: PeBanbif },
//       { logo: PePichinca },
//     ],
//     key: uuid(),
//   },
// ];

// export const pagoEfectivoCashChannels = [
//   {
//     data: [
//       { logo: PeBbva },
//       { logo: PeBcp },
//       { logo: PeInterbank },
//       { logo: PeScotia },
//       { logo: PeCaja },
//       { logo: PePambo },
//       { logo: PeWu },
//       { logo: PeDisashop },
//       { logo: PeKasnet },
//       { logo: PeCellPower },
//       { logo: PeGanaste },
//       { logo: PeMoneyGram },
//       { logo: PeNiuBiz },
//       { logo: PeRedDigit },
//     ],
//     key: uuid(),
//   },
// ];

// export const payCashChannels = {
//   ECU: [
//     {
//       data: [
//         { logo: Pc10, key: uuid() },
//         { logo: Pc2, key: uuid() },
//         { logo: Pc3, key: uuid() },
//         { logo: Pc4, key: uuid() },
//         { logo: Pc11, key: uuid() },
//         { logo: Pc7, key: uuid() },
//         { logo: Pc9, key: uuid() },
//         { logo: Pc1, key: uuid() },
//         // { logo: PePichinca, key: uuid() },
//         { logo: Azuayo, key: "Ecu-Azuayo" },
//         // { logo: BancoInternacional, key: uuid() },
//         { logo: Coac29, key: uuid() },
//         { logo: Coopmego, key: uuid() },
//         { logo: Economicas, key: uuid() },
//         { logo: Facilito, key: uuid() },
//         // { logo: Guayaquil, key: uuid() },
//         { logo: Hiper_market, key: uuid() },
//         { logo: Matualista, key: uuid() },
//         { logo: Muchuc, key: uuid() },
//         { logo: CruzAzul, key: uuid() },
//       ],
//       key: uuid(),
//     },
//   ],
//   MEX: [
//     {
//       data: [
//         { logo: PcMex1, key: uuid() },
//         { logo: PcMex2, key: uuid() },
//         { logo: SamsClub, key: uuid() },
//         { logo: PcMex4, key: uuid() },
//         { logo: PcMex5, key: uuid() },
//         { logo: PcMex6, key: uuid() },
//         { logo: PcMex7, key: uuid() },
//         { logo: PcMex8, key: uuid() },
//         { logo: PcMex9, key: uuid() },
//         { logo: PcMex10, key: uuid() },
//         { logo: PcMex11, key: uuid() },
//         { logo: PcMex12, key: uuid() },
//         { logo: PcMex13, key: uuid() },
//         { logo: PcMex14, key: uuid() },
//         { logo: Santander, key: uuid() },
//         { logo: PeBbva, key: uuid() },
//         // { logo: Ahorro, key: uuid() },
//         { logo: Alianza, key: uuid() },
//         // { logo: Banbajio, key: uuid() },
//         { logo: Banorte, key: uuid() },
//         // { logo: Bogeda, key: uuid() },
//         { logo: Hsbc, key: uuid() },
//         { logo: Kiosko, key: uuid() },
//         // { logo: Lagunitas, key: uuid() },
//         // { logo: Merza, key: uuid() },
//         { logo: Morelia, key: uuid() },
//         { logo: Oblatos, key: uuid() },
//         { logo: Smbrural, key: uuid() },
//         { logo: Telecom, key: uuid() },
//         { logo: Via, key: uuid() },
//         { logo: Yza, key: uuid() },
//         { logo: Afirme, key: uuid() },
//       ],
//       key: uuid(),
//     },
//   ],
//   PER: [
//     {
//       data: [
//         { logo: PeBbva, key: "Per-Bbva" },
//         { logo: PeKasnet, key: "Per-Kasnet" },
//         { logo: PeWu, key: uuid() },
//         // { logo: PeInterbank, key: uuid() },
//         // { logo: PeBanbif, key: uuid() },
//         // { logo: PePichinca, key: uuid() },
//         { logo: PeBcp, key: "Per-Bcp" },
//         // { logo: PePambo, key: uuid() },
//         // { logo: PeNiuBiz, key: uuid() },
//         { logo: PerAndes, key: uuid() },
//         { logo: PerCajadel, key: uuid() },
//         { logo: PerCajapalla, key: uuid() },
//         // { logo: PerFullcargo, key: uuid() },
//         { logo: PerQapaq, key: uuid() },
//         { logo: PerRaiz, key: uuid() },
//         { logo: PerTrujillo, key: uuid() },
//         // { logo: PeBanbif, key: uuid() },
//         { logo: AgenteCash, key: uuid() },
//         // { logo: Comercio, key: uuid() },
//         { logo: Edypyme, key: uuid() },
//         // { logo: Megapunto, key: uuid() },
//         { logo: Sullana, key: uuid() },
//       ],
//       key: uuid(),
//     },
//   ],
//   CRI: [
//     {
//       data: [
//         // { logo: PcMex4, key: uuid() },
//         { logo: CriNacional, key: "Cri-Bncr" },
//         { logo: CriPayser, key: uuid() },
//         { logo: Hey, key: uuid() },
//       ],
//       key: uuid(),
//     },
//   ],
//   GTM: [
//     {
//       data: [
//         // { logo: PcMex4, key: uuid() },
//         { logo: GtmBam, key: "Gtm-Bam" },
//         // { logo: GtmBanrural, key: uuid() },
//         // { logo: GtmBantrab, key: uuid() },
//         { logo: GtmContinental, key: "Gtm-Continental" },
//         // { logo: Ficohsa, key: uuid() },
//         // { logo: InterBanco, key: uuid() }
//       ],
//       key: uuid(),
//     },
//   ],
//   PAN: [
//     {
//       data: [
//         { logo: PeWu, key: uuid() },
//         { logo: PanMachetazo, key: uuid() },
//         { logo: PanXtra, key: uuid() },
//         { logo: PanJuan, key: uuid() },
//         // { logo: Ficohsa, key: uuid() }
//       ],
//       key: uuid(),
//     },
//   ],
// };

// export const payCashChannels = {
//   ECU: [
//     { data: [{ logo: Pc10 }], key: uuid() },
//     { data: [{ logo: Pc2 }], key: uuid() },
//     { data: [{ logo: Pc3 }], key: uuid() },
//     { data: [{ logo: Pc4 }], key: uuid() },
//     { data: [{ logo: Pc11 }], key: uuid() },
//     { data: [{ logo: Pc7 }], key: uuid() },
//     { data: [{ logo: Pc9 }], key: uuid() },
//     { data: [{ logo: Pc1 }], key: uuid() },
//     // { data: [{ logo: PePichinca }], key: uuid() },
//     { data: [{ logo: Azuayo }], key: uuid() },
//     // { data: [{ logo: BancoInternacional }], key: uuid() },
//     { data: [{ logo: Coac29 }], key: uuid() },
//     { data: [{ logo: Coopmego }], key: uuid() },
//     { data: [{ logo: Economicas }], key: uuid() },
//     { data: [{ logo: Facilito }], key: uuid() },
//     // { data: [{ logo: Guayaquil }], key: uuid() },
//     { data: [{ logo: Hiper_market }], key: uuid() },
//     { data: [{ logo: Matualista }], key: uuid() },
//     { data: [{ logo: Muchuc }], key: uuid() },
//     { data: [{ logo: CruzAzul }], key: uuid() },
//   ],
//   MEX: [
//     { data: [{ logo: PcMex1 }], key: uuid() },
//     { data: [{ logo: PcMex2 }], key: uuid() },
//     { data: [{ logo: SamsClub }], key: uuid() },
//     { data: [{ logo: PcMex4 }], key: uuid() },
//     { data: [{ logo: PcMex5 }], key: uuid() },
//     { data: [{ logo: PcMex6 }], key: uuid() },
//     { data: [{ logo: PcMex7 }], key: uuid() },
//     { data: [{ logo: PcMex8 }], key: uuid() },
//     { data: [{ logo: PcMex9 }], key: uuid() },
//     { data: [{ logo: PcMex10 }], key: uuid() },
//     { data: [{ logo: PcMex11 }], key: uuid() },
//     { data: [{ logo: PcMex12 }], key: uuid() },
//     { data: [{ logo: PcMex13 }], key: uuid() },
//     { data: [{ logo: PcMex14 }], key: uuid() },
//     { data: [{ logo: Santander }], key: uuid() },
//     { data: [{ logo: PeBbva }], key: uuid() },
//     // { data: [{ logo: Ahorro }], key: uuid() },
//     { data: [{ logo: Alianza }], key: uuid() },
//     // { data: [{ logo: Banbajio }], key: uuid() },
//     { data: [{ logo: Banorte }], key: uuid() },
//     // { data: [{ logo: Bogeda }], key: uuid() },
//     { data: [{ logo: Hsbc }], key: uuid() },
//     { data: [{ logo: Kiosko }], key: uuid() },
//     // { data: [{ logo: Lagunitas }], key: uuid() },
//     // { data: [{ logo: Merza }], key: uuid() },
//     { data: [{ logo: Morelia }], key: uuid() },
//     { data: [{ logo: Oblatos }], key: uuid() },
//     { data: [{ logo: Smbrural }], key: uuid() },
//     { data: [{ logo: Telecom }], key: uuid() },
//     { data: [{ logo: Via }], key: uuid() },
//     { data: [{ logo: Yza }], key: uuid() },
//     { data: [{ logo: Afirme }], key: uuid() },
//   ],
//   PER: [
//     { data: [{ logo: PeBbva }], key: "Per-Bbva" },
//     { data: [{ logo: PeKasnet }], key: "Per-Kasnet" },
//     { data: [{ logo: PeWu }], key: uuid() },
//     // { data: [{ logo: PeInterbank }], key: uuid() },
//     // { data: [{ logo: PeBanbif }], key: uuid() },
//     // { data: [{ logo: PePichinca }], key: uuid() },
//     { data: [{ logo: PeBcp }], key: "Per-Bcp" },
//     // { data: [{ logo: PePambo }], key: uuid() },
//     // { data: [{ logo: PeNiuBiz }], key: uuid() },
//     { data: [{ logo: PerAndes }], key: uuid() },
//     { data: [{ logo: PerCajadel }], key: uuid() },
//     { data: [{ logo: PerCajapalla }], key: uuid() },
//     // { data: [{ logo: PerFullcargo }], key: uuid() },
//     { data: [{ logo: PerQapaq }], key: uuid() },
//     { data: [{ logo: PerRaiz }], key: uuid() },
//     { data: [{ logo: PerTrujillo }], key: uuid() },
//     // { data: [{ logo: PeBanbif }], key: uuid() },
//     { data: [{ logo: AgenteCash }], key: uuid() },
//     // { data: [{ logo: Comercio }], key: uuid() },
//     { data: [{ logo: Edypyme }], key: uuid() },
//     // { data: [{ logo: Megapunto }], key: uuid() },
//     { data: [{ logo: Sullana }], key: uuid() },
//   ],
//   CRI: [
//     // { data: [{ logo: PcMex4 }], key: uuid() },
//     // { data: [{ logo: CriNacional }], key: uuid() },
//     { data: [{ logo: CriPayser }], key: uuid() },
//     { data: [{ logo: Hey }], key: uuid() },
//   ],
//   GTM: [
//     // { data: [{ logo: PcMex4 }], key: uuid() },
//     { data: [{ logo: GtmBam }], key: "Gtm-Bam" },
//     // { data: [{ logo: GtmBanrural }], key: uuid() },
//     // { data: [{ logo: GtmBantrab }], key: uuid() },
//     { data: [{ logo: GtmContinental }], key: "Gtm-Continental" },
//     // { data: [{ logo: Ficohsa }], key: uuid() },
//     // { data: [{ logo: InterBanco }], key: uuid() },
//   ],
//   PAN: [
//     { data: [{ logo: PeWu }], key: uuid() },
//     { data: [{ logo: PanMachetazo }], key: uuid() },
//     { data: [{ logo: PanXtra }], key: uuid() },
//     // { data: [{ logo: Ficohsa }], key: uuid() },
//   ],
// };

// method-channel pair for /checkout page
export const checkoutConfig = [
  "PIX-BS2",
  "PIX-Bradesco",
  "PIX-Rendimento",
  "PIX-Nupay",
  "PIX-Genial",
  "PIX-StarkBank",
  "PIX-StarkBankTransferSmile",
  "PIX-CHANNEL_GROUP_ROUTER",
  "PIX-Mercadopago",
];

export const countryCode2TaxIdType = {
  BRA: ["CPF", "CNPJ"],
  MEX: ["RFC", "CURP"],
  CHL: ["RUT/RUN"],
  COL: ["NIT", "CC", "CE"],
  PER: ["DNI", "RUC"],
  ECU: ["RUC", "CE", "CI"],
  PAN: ["CIP"],
  CRI: ["CDI"],
  SLV: ["DUI"],
  GTM: ["DPI"],
  NIC: ["CIP"],
  ARG: ["DNI", "CUIT", "CUIL", "CDI"],
  URY: ["CI", "RUT"],
  BOL: ["NIT", "CI", "CE"],
  PRY: ["RUC", "CI"],
};

export const latinCountries = [
  "MEX",
  "CHL",
  "COL",
  "PER",
  "ECU",
  "BRA",
  "PAN",
  "CRI",
  "SLV",
  "GTM",
  "NIC",
  "ARG",
  "URY",
  "BOL",
  "PRY",
];

export const countryCode3to2 = {
  MEX: "MX",
  CHL: "CL",
  COL: "CO",
  PER: "PE",
  ECU: "EC",
  BRA: "BR",
  PAN: "PA",
  CRI: "CR",
  SLV: "SV",
  GTM: "GT",
  NIC: "NI",
  HKG: "HK",
  PHL: "PH",
  KOR: "KR",
  IDN: "ID",
  MYS: "MY",
  THA: "TH",
  CHN: "CN",
  KHM: "KH",
  SGP: "SG",
  SAU: "SA",
  ARE: "AE",
  KWT: "KW",
  QAT: "QA",
  OMN: "OM",
  IND: "IN",
  RUS: "RU",
  EGY: "EG",
  GBR: "GB",
  BHR: "BH",
  ARG: "AR",
  URY: "UY",
  BOL: "BO",
  PRY: "PY",
  AUS: "AU",
  POL: "PL",
  NZL: "NZ",
  CHE: "CH",
  CAN: "CA",
  USA: "US",
};

export const formatCurrency = {
  BRL: "R$",
  MXN: "Mex$",
  RUB: "руб",
  USD: "US$",
  SGD: "SG$",
  CNY: "¥",
  EUR: "€",
};

////////////////////////////////////////////////////
