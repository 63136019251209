import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { checkoutConfig } from "../utils/configurations";
import { useSelector } from "react-redux";

function RequireAuth({ children, ...props }) {
  const { method, payChannel, tradeStatus } = useSelector((state) => state);
  const { pathname, search } = useLocation();
  // console.log("match is: >>>  ", search);
  const tradeStatusId = tradeStatus.id;
  const checkoutPageAuth = checkoutConfig.includes(`${method}-${payChannel}`);
  // console.log("match :", match);
  // console.log("tradeStatus: ", tradeStatus);
  if (pathname === "/") {
    if (tradeStatusId !== "trade") {
      if (tradeStatusId === "process" && checkoutPageAuth) {
        return <Navigate to={"/checkout" + search} />;
      }
    }
  } else if (pathname === "/checkout") {
    if (tradeStatusId === "trade") {
      return <Navigate to={"/" + search} />;
    }
  }

  return children;
}

export default RequireAuth;
