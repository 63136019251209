import React from "react";
import TextFieldCustom from "./TextFieldCustom";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useTranslation } from "react-i18next";
import { checkCardNumber } from "../../utils/validations";
import { formatCreditCardNumber, getBankPaymentIcon } from "../../utils/formats";
import Ccard from "../../img/credit_card.svg";

function CreditCardNumber({ form, setForm, setFocusOnInput = () => {}, id }) {
  const { t } = useTranslation();
  return (
    <div>
      <TextFieldCustom
        fullWidth
        id={id || "num-cc"}
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        inputProps={{
          autocomplete: "new-password",
          "ga-data": "341",
          inputMode: "numeric",
        }}
        error={!form.cardNumber.valid}
        helperText={!form.cardNumber.valid && t("invalid_card_no")}
        className="homeMobile__filling__content__input"
        placeholder="1234 1234 1234 1234"
        label={t("card_no")}
        variant="outlined"
        value={form.cardNumber.value}
        onChange={(event) => {
          let value = formatCreditCardNumber(event.target.value);
          setForm((prev) => {
            return {
              ...prev,
              cardNumber: { ...prev.cardNumber, value: value },
            };
          });
        }}
        onBlur={() => {
          checkCardNumber({ form: form, setForm: setForm });
        }}
        InputProps={{
          endAdornment: (
            <>
              <InputAdornment position="end">
                <img
                  src={getBankPaymentIcon(form.cardNumber.value)}
                  alt=""
                  style={{
                    height: "20px",
                    paddingRight: "3px",
                  }}
                />
              </InputAdornment>
              <InputAdornment position="end">
                <img
                  src={Ccard}
                  alt=""
                  style={{
                    height: "20px",
                    paddingRight: "3px",
                  }}
                />
              </InputAdornment>
            </>
          ),
        }}
      />
    </div>
  );
}

export default CreditCardNumber;
