import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setAlert } from "./utils/redux/reducer";
import Warning from "./img/warning.png";
import Success from "./img/success.png";
import Fail from "./img/fail.png";
import { makeStyles } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";

const useStyles = makeStyles({
  root: {
    "& .MuiDialog-paper": {
      alignItems: "center",
      padding: "20px",
      maxWidth: "400px",
      "& .MuiDialogContent-root": {
        textAlign: "center",
        padding: "10px",
        "& .MuiDialogContentText-root": {
          fontSize: "15px",
          color: "rgba(0, 0, 0, 0.84)",
          lineHeight: "1.4",
          margin: "0",
        },
      },
      // "& .MuiButton-root": {
      //   background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
      //   border: 0,
      //   borderRadius: 3,
      //   boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      //   color: "white",
      //   height: 30,
      //   padding: "0 30px",
      // },
    },
  },
  button: (props) => ({
    backgroundColor: props.button?.color,
    "&:hover": {
      backgroundColor: props.button?.color,
    },
    font: props.button?.font,
    textTransform: "none",
    border: 0,
    borderRadius: 3,
    color: "white",
    padding: "7px 22px",
  }),
});

function AlertDialog() {
  const { t } = useTranslation();
  const { alert, styles } = useSelector((state) => state);
  const dispatch = useDispatch();

  // console.log("alert: ", alert);

  const handleClose = (event) => {
    event.preventDefault();
    dispatch(setAlert({ state: false }));
    if (alert?.callback) alert.callback?.();
  };

  const classes = useStyles(styles);

  const dialogImg = {
    warning: Warning,
    success: Success,
    fail: Fail,
  };

  return (
    <Dialog
      open={alert?.state}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
      disableRestoreFocus
      TransitionComponent={Zoom}
    >
      {alert?.title && <DialogTitle id="alert-dialog-title">{alert.title}</DialogTitle>}
      <img src={dialogImg[alert.status] || Warning} alt="" style={{ height: "50px" }} />
      {alert?.message && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t(alert?.message)}</DialogContentText>
        </DialogContent>
      )}
      {alert.status === "warning" && (
        <DialogActions>
          <Button onClick={handleClose} className={classes.button}>
            {t("okay")}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default AlertDialog;
