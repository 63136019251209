import React from "react";
import "./CheckoutPix.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PixQr from "../../img/pixqr.png";
import QRCode from "qrcode.react";

function CheckoutPix(props) {
  const { payData } = useSelector((state) => state);
  const { t } = useTranslation();
  return (
    <div className="checkoutPix">
      <div>
        <div className="checkoutPix__instContainer">
          <p>
            Por favor abra o seu aplicativo de pagamento e escaneie o código QR abaixo para pagar ou copie o código Pix
            abaixo e cole em seu app de pagamento para finalizar a compra.
          </p>
        </div>
      </div>
      <div className="checkoutPix__content">
        {payData?.qr_code && (
          <>
            <QRCode
              value={payData?.qr_code}
              size={128}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"M"}
              includeMargin={false}
              renderAs={"svg"}
              imageSettings={{
                src: PixQr,
                x: null,
                y: null,
                height: 24,
                width: 24,
                excavate: true,
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default CheckoutPix;
