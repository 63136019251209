import { useSelector } from "react-redux";
import CheckoutPix from "./CheckoutPix";

function Checkout(props) {
  const { method, payChannel } = useSelector((state) => state);
  return (
    <div>
      {method === "PIX" &&
        (payChannel === "BS2" ||
          payChannel === "Bradesco" ||
          payChannel === "Rendimento" ||
          payChannel === "Nupay" ||
          payChannel === "Genial" ||
          payChannel === "StarkBank" ||
          payChannel === "CHANNEL_GROUP_ROUTER" ||
          payChannel === "Mercadopago" ||
          payChannel === "StarkBankTransferSmile") && <CheckoutPix />}
    </div>
  );
}

export default Checkout;
