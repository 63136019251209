import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { privacyEn, privcayPt } from "./Privacy Policy/privacy";
import { termsOfServiceEn, termsOfServicePt, termsOfServiceEs } from "./Terms of Use/terms";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    // lng: "es",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          ...privacyEn,
          ...termsOfServiceEn,
          en: "English",
          es: "Spanish",
          pt: "Portuguese",
          ru: "Russian",
          ar: "Arabic",
          faq: "Frequently Asked Questions",
          efecty_faq1q: "What is Efecty?",
          efecty_faq1a:
            "Efecty is a company based in Colombia that offers a variety of payment methods including money orders, payments, recharges and collections nationwide. Customers can pay online and pay later at any of the 8000 service points in the country.",
          efecty_faq2q: "How can I find a place to pay in cash?",
          efecty_faq2a: "Go to https://mapapap.efecty.com.co/pagos-personas and search your address",
          efecty_faq3q: "How to know if my order was succesful?",
          efecty_faq3a:
            "The purchases are approved immediately and you will receive an email confirming your payment. If you have doubts with the purchase you are making, call us at the customer service lines.",
          khipu_faq1q: "What is Khipu?",
          khipu_faq1a:
            "Khipu is a way to receive payments with transfer that works on any device, responsively, and any browser.",
          khipu_faq2q: "How does Kihpu work?",
          khipu_faq2a:
            "Simply input your Khipu information (card number and password) and complete the transaction on your bank's website or app.",
          khipu_faq3q: "How to know if my order was succesful?",
          khipu_faq3a:
            "The purchases are approved immediately and you will receive an email confirming your payment. If you have doubts with the purchase you are making, call us at the customer service lines.",
          baloto_faq1q: "How to pay with VIA Baloto?",
          baloto_faq1a:
            "Tell the info to the cashier in the VIA Baloto point of your preference and check if everything is the same as in the receipt. This voucher is only valid for this order. If you make two payments, they will be cancelled.",
          sured_faq1q: "How to pay with Su Red?",
          sured_faq1a:
            "Tell the info to the cashier in the Su Red point of your preference and check if everything is the same as in the receipt. This voucher is only valid for this order. If you make two payments, they will be cancelled.",
          gana_faq1q: "How to pay with Gana?",
          gana_faq1a:
            "Tell the info to the cashier in the Gana point of your preference and check if everything is the same as in the receipt. This voucher is only valid for this order. If you make two payments, they will be cancelled.",
          help_chat: "Help chat",
          okay: "Okay!",
          select_notice: "Select a payment method/channel",
          instructions: "Instructions",
          videos: "Videos",
          pictures: "Pictures",
          text: "Text",
          online_inst1: "Log on to online banking as usual.",
          online_inst2: "Enter your 6 digit password.",
          online_inst3: "Select Payment and Services and companies category. Then enter in the search “SafetyPay”.",
          online_inst4:
            "Selecting this option will request the transaction number and the amount that was provided by the merchant.",
          online_inst5: "Confirm the transaction by entering your electronic key (token) and you are done!",
          payment_method: "Payment Method",
          online_banking: "Online Banking",
          cash_payments: "Cash Payments",
          bank_agent: "Choose Bank/Agent",
          filling_details: "Filling Details",
          fill_info: "Fill your info",
          details: "Details",
          name: "Name",
          email: "Email",
          TIN: "TIN (Tax ID):",
          phone: "Mobile Phone Number",
          time_remain: "Time remaining:",
          trx_id: "Transaction ID:",
          hide: "Hide",
          show: "Show",
          product_description: "Product Description:",
          order_value: "Order Value",
          fee_102: "Bank fee",
          notice_102: "",
          fee_101: "Tax",
          notice_101: "Note that based on Law No. 27,430, a {{key}} VAT will be added to the value of this purchase.",
          fee_100: "Other fees",
          notice_100: "",
          fee_104: "Country",
          notice_104:
            "According to Law 27541 and Decree 99/2019, it can include a percentage of 8% or 30% on the value of the transaction in pesos, depending on the type of goods / services purchased.",
          fee_105: "Profit",
          notice_105:
            "Note that based on General Resolution RG 4815/2020, 35% will be added for the purchase of foreign currency and 45% for other operations covered by the PAIS Tax.",
          discount: "Discount",
          total: "Total",
          confirm: "Confirm",
          return: "Return",
          privacy_policy_error:
            "You must read and accept the Terms of Use and Privacy Policy to complete your purchase.",
          privacy_policy1: "I have read and agree to the",
          privacy_policy2: " Terms of Use",
          privacy_policy3: " and",
          privacy_policy4: " Privacy Policy",
          privacy_policy5: " of Pagsmile",
          invalid_name: "Invalid name",
          invalid_TIN: "Invalid ID",
          invalid_email: "Invalid email",
          invalid_phone: "Invalid phone number",
          payment_inst_top:
            "Your order has been registered. We have registered your order and waiting for payment to start the delivery procedure. We're almost done!",
          payment_inst: "Payment Instructions:",
          payment_ticket: "Payment Ticket:",
          payment_code: "Payment Code",
          attention: "ATTENTION: Please check the updated information below.",
          cash: "Cash",
          pay: "Pay:",
          pay2: "Pay",
          processing: "Processing",
          processing_notice: "The transaction is in process, it's going to be quick. Thank you for using Pagsmile!",
          refund_in_process: "The refund is in process, it's going to be quick. Thank you for using Pagsmile!",
          success: "Payment successful",
          success_result: "Success",
          success_notice:
            "You have paid the order successfully. Please return to seller page! Thank you for using Pagsmile.",
          cancelled: "Cancelled",
          order_timeout:
            "The time limit for your payment has ended, please return back to seller page to make a new order.",
          failed: "Failed",
          copied: "Copied Successfully",
          copy: "Copy",
          visit_us: "To know more about us, visit",
          fail_notice: "It is possible that an information is wrong, try again or reach us",
          fail_query: "Do you want to try again?",
          credit_debit_card: "Credit/Debit Card",
          credit_card: "Credit Card",
          debit_card: "Debit Card",
          oxxo: "OXXO Cash Payment",
          wallet: "Wallets",
          yes: "Yes, I do",
          help_me: "Help me",
          order: "Order",
          back_shop: "Back to shopping",
          cardholder_name: "Cardholder’s full name",
          card_no: "Card Number",
          invalid_card_no: "Invalid card no",
          exp_year: "Exp. Year",
          exp_date: "Expiration Date",
          invalid_exp_date: "Invalid expiration date",
          invalid_year: "Select a year",
          exp_month: "Exp. Month",
          month: "month",
          invalid_month: "Select a month ",
          state: "State",
          invalid_state: "Select a state",
          city: "City",
          invalid_city: "Select a city",
          postal_code: "Postal Code",
          invalid_postal_code: "Invalid postal code",
          street: "Street",
          invalid_street: "Invalid street",
          number: "No",
          invalid_number: "Invalid number",
          source: "Website or name of the APP where you purchased the product",
          invalid_source: "Invalid",
          source_notice: "In case of incorrect filling may be failure to pay the order",
          todito_notice: "Input your Todito Cash information below:",
          "checkout_inst1.1": "Dear ",
          "checkout_inst1.2": "user,",
          "checkout_inst1.3": " pay ",
          "checkout_inst1.4": " to finish your purchase.",
          "checkout_inst2.1": "Scan the QR code to go to ",
          "checkout_inst2.1.1": "Click the button to go to ",
          "checkout_inst2.2": " App and complete the payment.",
          go_to: "Go to ",
          checkout_inst3: "1. Go to the closest store",
          checkout_inst4: "2. Tell the cashier you have a Pagsmile payment and show this number: ",
          share: "Share",
          save_pdf: "Save PDF",
          checkout_inst5: "Open your bank app and input the info below",
          checkout_inst6: "Input this reference number: ",
          register_info: "Info to register account",
          installments: "Installments",
          invalid_cvv: "Invalid CVC",
          invalid_PIN: "Invalid PIN",
          invalid_NIP: "Invalid NIP",
          paid_major: "Major part paid",
          paid_minor: "Minor part paid",
          refund_verifying: "Verifying refund",
          refund_processing: "Processing refund",
          risk_control: "Controlling risks",
          refunded: "Refunded",
          partial_refund: "Partial refunded",
          chargeback: "Chargeback",
          chargeback_reversed: "Chargeback Reversed",
          dispute: "Dispute",
          dispute_reversed: "Dispute reversed",
          timeout: "Transaction timed Out",
          payment_refuse: "Payment refused",
          payment_fail: "Payment failed",
          refund_reverse: "Refund reversed",
          refund_refuse: "Refund refused",
          pay_close: "Closed",
          fail_sec: "For the security of your transaction, this order is closed. Please submit it again.",
          invalid_cep: "Invalid CEP",
          boleto_inst1: "Boleto generated!",
          boleto_inst2: "You can pay in banks, lotéricas, Correios, supermarkets or online.",
          boleto_inst3:
            "We've already sent you an email with the details and you can also save a file now by clicking on the 'Save PDF' button below.",
          deposit_upload_inst1: "Nice to have you back!",
          deposit_upload_inst2:
            "We're almost there, just fill in this short form and upload the proof of payment to finish :)",
          "deposit_upload_inst3.1": "Please fill in ",
          "deposit_upload_inst3.2": "your",
          "deposit_upload_inst3.3": " bank account details.",
          upload: "Upload",
          back: "Back",
          wallet_inst1: "Scan and pay",
          wallet_inst2: "You can scan and pay using:",
          wallet_inst3: "How to pay?",
          wallet_inst4: "1. Open your wallet app on your device",
          "wallet_inst4.1": "1. Open your bank app on your device",
          wallet_inst5: "2. Tap on the “scan” feature",
          wallet_inst6: "3. Scan payment QR and complete the payment",
          wallet_mob_ins1: "Click and pay",
          wallet_mob_ins2: "You can click and pay using:",
          wallet_mob_ins3: "1. Click below button to go to ",
          "wallet_mob_ins3.1": " APP on your phone",
          wallet_mob_ins4: "2. Proceed to complete the payment",
          pago_method: "Mobile banking, QR (Yape, Plin), Agents and Warehouses",
          pago_agent_title: "Agents",
          pago_agent1: "1. Indicate that you are going to make a payment to the company PAGOEFECTIVO.",
          pago_agent2: "2. Indicate that you will make the payment to PAGOEFECTIVO Soles.",
          "pago_agent3.1": "3. Indicate the CIP Code: ",
          "pago_agent3.2": " and the amount to pay.",
          pago_bank_title: "by Bank Agencies",
          pago_bank1: "1. Indicate that you are going to make a payment to the company PAGOEFECTIVO.",
          pago_bank2: "2. Indicate that you will make the payment to PAGOEFECTIVO Soles",
          "pago_bank3.1": "3. Indicate the CIP Code: ",
          "pago_bank3.2": " and the amount to pay.",
          pago_online_title: "Online Banking",
          pago_online1: "1. Select the Payment for Services option.",
          pago_online2: "2. Search and select the institution / company CASH PAYMENT.",
          pago_online3: "3. Select CASH PAYMENT Soles.",
          "pago_online4.1": "4. Enter your CIP code: ",
          "pago_online4.2": " and follow the steps.",
          clap_cash_title: "How to pay?",
          pay_cash_ins1:
            "Go to your nearest cash payment point and mention to the cashier that you are going to make a PAYCASH payment with the code ",
          pay_cashgtm_ins1:
            "Go to the nearest cash payment point, mention to the cashier that you are going to make a payment to the agreement {{key}} with the code ",
          clap_cash_ins1: "Go to your nearest Klap EFECTIVO point and request to pay with the code ",
          clap_cash_ins2: "Tell the person at the merchant that the options on the machine are 2-8-1",
          clap_cash_ins3: "Pay and receive your receipt",
          klap_store1: "Use",
          klap_store2: " this link ",
          klap_store3: "to search for your nearest Klap store",
          dexp1: "Payment is made in two steps:",
          dexp2: "1. Make a transfer, deposit or PIX",
          dexp3: "2. Send proof of payment",
          dexp4: "Bank name:",
          dexp5: "Agency",
          dexp6: "Current account:",
          dexp7: "Card holder's name:",
          dexp8: "PIX key:",
          dexp9: "Type of payment",
          dexp10: "Invalid payment type",
          dexp11: "The correct format is 4 to 6 digits, there may be '-' in the middle",
          dexp12:
            "The correct format is 5 to 12 digits, there should be a '-' in the middle, and the last digit is a number or x(X).",
          dexp13: "Upload proof of payment",
          dexp14: "Please upload a file",
          dexp15: "Transfer at the same bank",
          dexp16: "TED inter banks",
          dexp17: "Cash deposit",
          dexp18: "Envelope deposit",
          dexp19: "Send the proof",
          dexp20: "Full name",
          dexp21: "File uploaded successfully. Please wait for the processing and confirmation of your payment.",
          account: "Account",
          bank: "Bank",
          drag_notice: "You can drag and drop or click the box",
          fail_try_agin: "Something went wrong, please try again",
          fail_change_method: "Something went wrong. Please use another payment method.",
          fail_upload_again: "Something went wrong. Please try to upload again",
          fail_upload: "Upload failed",
          timed_out: "Transaction request timed out!",
          no_channel: "No channel, please contact merchant",
          subscription: "You're subscribing",
          subscription_billed: "billed",
          subscription_every: "every",
          subscription_trial: "for the trial-period",
          subscription_then: ", then",
          trial_period: "trial-period",
          and: "and",
          day: "day",
          days: "days",
          week: "week",
          weeks: "weeks",
          months: "months",
          year: "year",
          years: "years",
          ref_no: "Reference no: ",
          agree_code: "Agreement No: ",
          id: "ID: ",
          amount: "Amount: ",
          due_date: "Due date",
          pay_points1: "You can click",
          pay_points2: " here ",
          pay_points3: "to see all payment points.",
          pay_points_search: " to search nearest payment point.",
          payvalida_notice: "Receipt {{key}} generated!",
          payvalida_notice2:
            "To make your payment, provide the following information at the {{key}} point of your choice.",
          select_country: "Please select a country first!",
          country: "Country",
          invalid_country: "Select a country",
          state_city: "State/City",
          invalid_state_city: "Invalid State/City",
          refresh: "Refresh",
          refresh_notice:
            "The page will be refreshed automatically in 30 seconds, otherwise please click on the button below.",
          download: "Download",
          network_err: "Network error, please try again.",
          code_err: "The SMS verification code is incorrect, please re-enter",
          pix_notice:
            "Please avoid using PayPal to pay your PIX order for now. Choosing to do so will greatly increase the approval time of your order.",
          address_billing: "Billing address:",
          address_delivery: "Delivery address:",
          address_same: "Same as billing address",
          process_page1: "Rest easy, you'll receive a confirmation in your email while we process your payment.",
          process_page2: "You can close this page now.",
          barcode_step1: "Go to the nearest payment point to pay with the pay code ",
          "barcode_step1.1":
            "Go to the nearest {{key}} payment point and tell the cashier you want to make a payment to PAYCASH with the code ",
          payment_point: "payment point",
          bank_list: "Bank List",
          bank_select: "Select a bank",
          agent: "Agency",
          agent_invalid: "Invalid agency",
          agent_select: "Select an agent",
          account_number: "Account Number",
          account_invalid: "Invalid account number",
          checking_account: "Checking account",
          saving_account: "Saving account",
          master_account: "Master account",
          pb_ins: "Enter the Player's Bank app, tap Pix, choose Pay QR Code and scan the code below.",
          pb_mob_ins: "Tap the button below and finalize the payment in the APP.",
          lp_ins1: "The Payment can be done by bank transfer or deposit.",
          lp_ins2: "Expiration date: ",
          lp_ins3: "Reference number: ",
          lp_ins4: "Account Information:",
          lp_ins5: "Bank name",
          account_type: "Account type",
          account_type_err: "Select an account type",
          lp_ins7: "Account number",
          lp_ins8: "Beneficiary name",
          lp_ins9: "Document type",
          lp_ins10: "Document ID",
          lp_ins11: "The reference number must be exactly the same.",
          trsf1: "Open your financial institutions app",
          trsf2: "Go to: Services > Transactions > Transfiya",
          trsf3: "Select the account you want to pay with",
          trsf4: "Pay to this account: ",
          trsf5: "Remember to transfer the exact amount of ",
          trsf6: ", otherwise the transaction will fail",
          expiry_month: "Expiry month",
          invalid_month2: "Invalid month",
          expiry_year: "Expiry year",
          invalid_year2: "Invalid year",
          spei_key: "This key can only be used once",
          spei_clabe: "Interbank's CLABE",
          spei_bank: "Destination bank",
          spei_recipient: "Recipient",
          blank: "",
          pb_qr: "Player's Bank QR Code",
          pb_card: "Player's Bank Card",
          pci_notice: "All transactions are safely guaranteed and compliant with the protocol PCI DSS.",
          "payment.redirect.notice1": "You will be redirected to the merchant's page in {{key}} seconds.",
          "payment.redirect.notice2": "Click ",
          "payment.redirect.notice3": "here",
          "payment.redirect.notice4": " to cancel.",
          service_name: "Service",
          pay_amount: "Amount",
          pay_code: "Payment code",
          mon_bankMobile: "Mobile",
          mon_bankWeb: "Computer",
          mon_bank: "Bank",
          mon_agent: "Store",
          mon_chooseBank: "1. Choose where you want to pay",
          mon_chooseMethod: "2. Choose payment method",
          address: "Address",
          invalid_address: "Invalid address",
          pay_or_fail: "Remember to make the payment within 15 minutes, otherwise the transaction will fail",
          ben_info: "Beneficiary information",
          bank_acc_no: "Bank account number",
          bank_acc_no_err: "Invalid bank account number",
          sec_code: "Security code",
          sec_code_err: "Invalid security code",
          cash_ins1: "Enter the payment section and search for Paycash by name",
          cash_ins2: "Enter the last {{key}} digits of the reference number and confirm the payment",
          cash_ins3: "Enter the client token and wait for confirmation of the operation",
          cash_ins4: "Enter the {{key}} digits of the reference number and confirm the payment",
          fill_all: "Please fill in all required fields",
          digital_bank1: "Select Services, Collections",
          digital_bank2: "Payment of basic services, taxes and catalog 2. Other collections PayCash",
          digital_bank3: "Enter reference and payment amount",
          bncr_paycash0:
            "Go to the nearest BN Servicios point and ask the cashier to make a PayCash payment with the code ",
          bncr_paycash1: "Enter the Internet Banking of Banco Nacional with your identification and password",
          bncr_paycash2: "Perform search by name: Paycash and enter a 16-digit reference",
          bncr_paycash3: "Payment is confirmed, select the payment method and wait for confirmation",
          digital_banking: "Digital banking",
          bncr_cash1:
            "Go to the nearest BN Servicios point and ask the cashier to make a {{key}} payment with the reference number provided above",
          bncr_cash2: "Pay and receive your voucher",
          bncr_bank1: "Enter the Internet Banking of Banco Nacional with your identification and password",
          bncr_bank2: "Perform search by name: Payválida and enter the reference number",
          bncr_bank3: "Reference will be confirmed, select the payment method and wait for confirmation",
          edit_address: "Edit address",
          order_expired: "Order expired",
          exp_date_placeholder: "MM/YY",
          verification_code: "Verification code",
          redirecting: "Redirecting...",
          20000: "Service currently unavailable",
          40000: "Transaction failed",
          40002: "Transaction failed",
          40012: "Order does not exist",
          40013: "Order has ended",
          40014: "Order information is inconsistent",
          40015: "Maximum amount (including fees) exceeded",
          40101: "Order timeout",
          40201: "Failed to upload",
          40301: "Bank is busy",
          "SYS-0001":
            "Your payment has been declined. Choose another payment method. We recommend payment methods in cash.",
          "SYS-0002": "Time out",
          "SYS-0003": "Invalid transaction amount",
          "SYS-0004": "Payer must provide a valid email",
          "CC-0001": "Invalid operators users involved",
          "CC-0002": "Invalid card token",
          "CC-0003": "Pending contingency",
          "CC-0004": "Pending review manual",
          "CC-0005": "Rejected, bad filled card number",
          "CC-0006": "Rejected, bad filled date",
          "CC-0007": "Rejected, bad filled other",
          "CC-0008": "Rejected, bad filled security code",
          "CC-0009": "Rejected, in blacklist",
          "CC-0010": "Rejected, call for authorize",
          "CC-0011": "Rejected, card disabled",
          "CC-0012": "Rejected, card error",
          "CC-0013": "Rejected, duplicated payment",
          "CC-0014": "Rejected, high risk",
          "CC-0015": "Rejected, insufficient amount",
          "CC-0016": "Rejected, invalid installments",
          "CC-0017": "Rejected, max attempts",
          "CC-0018": "Rejected, other reason",
          70001: "We are unable to process your payment.",
          80004: "User is blacklisted",
          "70001-10001": "The CPF entered is invalid, enter another one",
          "70001-10002": "This transaction cannot be processed. Contact Customer Support",
          "70001-10003": "This transaction cannot be processed. Contact Customer Support",
          "70001-10004": "This transaction cannot be processed. Contact Customer Support",
          "70001-20001": "Transaction limit exceeded",
          "70001-99999": "This transaction cannot be processed. Contact Customer Support",
        },
      },
      es: {
        translation: {
          ...privcayPt,
          ...termsOfServiceEs,
          en: "Inglés",
          es: "Español",
          pt: "Portugués",
          ru: "Ruso",
          ar: "Arábica",
          faq: "Preguntas Frecuentes",
          efecty_faq1q: "¿Qué es Efecty?",
          efecty_faq1a:
            "Efecty es una empresa con sede en Colombia que ofrece una variedad de métodos de pago que incluyen giros postales, pagos, recargas y cobros en todo el país. Los clientes pueden pagar en línea y pagar más tarde en cualquiera de los 8000 puntos de servicio en el país.",
          efecty_faq2q: "¿Cómo puedo encontrar un sitio para pagar en efectivo?",
          efecty_faq2a: "Ve a https://mapapap.efecty.com.co/pagos-personas y busca tu dirección",
          efecty_faq3q: "¿Cómo saber si mi pedido fue exitoso?",
          efecty_faq3a:
            "Las compras se aprueban inmediatamente y recibirá un correo electrónico confirmando su pago.  Si tienes dudas con la compra que estas realizando llámanos en las líneas de atendimento al cliente.",
          khipu_faq1q: "¿Qué es Khipu?",
          khipu_faq1a:
            "Khipu es una manera de recibir pagos con transferencia que funciona en cualquier dispositivo, de manera responsiva, y cualquier navegador.",
          khipu_faq2q: "¿Cómo funciona?",
          khipu_faq2a:
            "Simplemente ingrese su información de Khipu (número de tarjeta y contraseña) y complete la transacción en el sitio web o la aplicación de su banco.",
          khipu_faq3q: "¿Cómo saber si mi pedido fue exitoso?",
          khipu_faq3a:
            "Las compras se aprueban inmediatamente y recibirá un correo electrónico confirmando su pago.  Si tienes dudas con la compra que estas realizando llámanos en las líneas de atendimento al cliente.",
          baloto_faq1q: "¿Cómo pagar con VIA Baloto?",
          baloto_faq1a:
            "Menciona los datos al cajero en el punto VIA Baloto de tu preferencia y verifica que la información coincida con el recibo. Este cupón es válido para este pedido. Si realizas dos pagos, se cancelarán.",
          sured_faq1q: "¿Cómo pagar con Su Red?",
          sured_faq1a:
            "Menciona los datos al cajero en el punto Su Red de tu preferencia y verifica que la información coincida con el recibo. Este cupón es válido para este pedido. Si realizas dos pagos, se cancelarán.",
          gana_faq1q: "¿Cómo pagar con Gana?",
          gana_faq1a:
            "Menciona los datos al cajero en el punto Gana de tu preferencia y verifica que la información coincida con el recibo. Este cupón es válido para este pedido. Si realizas dos pagos, se cancelarán.",
          help_chat: "Chat de ayuda",
          okay: "Okay!",
          select_notice: "Escoger banco o agente",
          instructions: "Instruciones",
          videos: "Videos",
          pictures: "Fotos",
          text: "Texto",
          online_inst1: "Log on to online banking as usual.",
          online_inst2: "Enter your 6 digit password.",
          online_inst3: "Select Payment and Services and companies category. Then enter in the search “SafetyPay”.",
          online_inst4:
            "Selecting this option will request the transaction number and the amount that was provided by the merchant.",
          online_inst5: "Confirm the transaction by entering your electronic key (token) and you are done!",
          payment_method: "Método de pago",
          online_banking: "Banco en línea",
          cash_payments: "Pago en efectivo",
          bank_agent: "Escoger banco o agente",
          filling_details: "Detalles personales",
          fill_info: "Completa tu información",
          details: "Detalles",
          name: "Nombre",
          email: "Correo electrónico",
          TIN: "TIN (Tax ID)",
          cash: "Dinero",
          phone: "Número de teléfono móvil",
          time_remain: "Tiempo restante:",
          trx_id: "ID de la transacción:",
          hide: "Ocultar",
          show: "Mostrar",
          confirm: "Confirmar",
          return: "Return",
          attention: "ATENCIÓN: Por favor, consulta la siguiente información.",
          product_description: "Descripción del producto",
          order_value: "Valor del pedido",
          fee_102: "Comisión Bancaria",
          notice_102: "",
          fee_101: "Impuestos",
          notice_101: "Note que en base a la Ley N 27.430 se agregará un {{key}} de IVA al valor de esta compra.",
          fee_100: "Otras Tarifas",
          notice_100: "",
          fee_104: "Pais",
          notice_104:
            "Según la ley 27541 y el Decreto 99/2019, puede incluir un porcentaje de 8% o 30% sobre el valor de la transacción en pesos, dependiendo del tipo de bienes / servicios adquiridos.",
          fee_105: "Ganancias",
          notice_105:
            "Note que en base a la General Resolution RG 4815/2020 se agregará un 35% de Ganancias al valor de esta compra o un 45% si la operación es alcanzada por el Impuesto PAIS.",
          discount: "Descuento",
          total: "Total",
          privacy_policy_error:
            "Debe leer y aceptar los Términos de uso y la Política de privacidad para completar su compra.",
          privacy_policy1: "He leído y acepto los",
          privacy_policy2: " Términos de Uso",
          privacy_policy3: " y la",
          privacy_policy4: " Política de Privacidad",
          privacy_policy5: " de Pagsmile.",
          invalid_name: "Nombre inválido",
          invalid_TIN: "ID inválido",
          invalid_email: "Correo electrónico inválido",
          invalid_phone: "Número de teléfono inválido",
          payment_inst: "Instrucciones de pago:",
          payment_inst_top:
            "Registramos su pedido y estamos esperando el pago para iniciar la entrega. Ya casi terminamos!",
          payment_ticket: "Tíquete de pago",
          payment_code: "Código de pago",
          pay: "Pagar:",
          pay2: "Pagar",
          processing: "Procesando",
          processing_notice: "La transacción está en proceso, solo se queda un rato. ¡Gracias por usar Pagsmile!",
          refund_in_process: "El reembolso está en proceso, solo se queda un rato. ¡Gracias por usar Pagsmile!",
          success: "Pago exitoso",
          success_result: "éxito",
          success_notice:
            "Has pagado el pedido correctamente. Si quieras, regrese a la página del vendedor. ¡Gracias por usar Pagsmile!",
          cancelled: "Cancelado",
          order_timeout:
            "El límite de tiempo para su pago ha finalizado, vuelva a la página del vendedor para realizar un nuevo pedido.",
          failed: "Falla",
          copied: "Copiado con éxito",
          copy: "Copiar",
          visit_us: "Para saber mas sobre nosotros, visite",
          fail_notice:
            "Es posible que alguna información este incorrecta, intenta nuevamente o comunícate con nosotros",
          fail_query: "Quieres intentar de nuevo?",
          yes: "Sí, quiero",
          help_me: "Ayúdame",
          order: "Pedido",
          back_shop: "Volver a las compras",
          credit_card: "Tarjeta de crédito",
          credit_debit_card: "Tarjeta de crédito/débito",
          debit_card: "Tarjeta de débito",
          oxxo: "Pago en efectivo OXXO",
          wallet: "Billetera Digital",
          cardholder_name: "Nombre completo del titular de la tarjeta",
          card_no: "Número de la tarjeta",
          invalid_card_no: "Numero de tarjeta invalido",
          exp_year: "Año de venc.",
          exp_date: "Fecha de caducidad",
          invalid_exp_date: "Fecha de caducidad invalido",
          invalid_year: "Seleccione un año",
          exp_month: "Mes de venc.",
          month: "mes",
          invalid_month: "Selecciona un mes",
          state: "Estado",
          invalid_state: "Selecciona un Estado",
          city: "Ciudad",
          invalid_city: "Seleccionar una ciudad",
          postal_code: "Código postal",
          invalid_postal_code: "Código postal invalido",
          street: "Calle",
          invalid_street: "Calle invalido",
          number: "Número de Calle",
          invalid_number: "Número invalido",
          source: "Sitio web o nombre de la APP donde compró el producto",
          invalid_source: "Invalido",
          source_notice: "Si la información ingresada es incorrecta, el pago de su pedido podría fallar",
          todito_notice: "Ingrese su información de Todito Cash a continuación:",
          "checkout_inst1.1": "Estimado ",
          "checkout_inst1.2": "usuario,",
          "checkout_inst1.3": " pague ",
          "checkout_inst1.4": " para finalizar su compra.",
          "checkout_inst2.1": "Escanee el código QR para ir a la ",
          "checkout_inst2.1.1": "Haga clic en el botón para ir a la ",
          "checkout_inst2.2": " APP y complete el pago.",
          go_to: "Ir a ",
          checkout_inst3: "1. Ve a la tienda más cercana",
          checkout_inst4: "2. Dígale al cajero que tiene un pago de Pagsmile y muestre este número: ",
          share: "Compartir",
          save_pdf: "Guardar PDF",
          checkout_inst5: "Abra su aplicación bancaria e ingrese la información a continuación",
          checkout_inst6: "Ingrese este número de referencia: ",
          register_info: "Información para registrar la cuenta",
          installments: "Pago a plazos",
          invalid_cvv: "CVC invalido",
          invalid_PIN: "PIN invalido",
          invalid_NIP: "NIP invalido",
          paid_major: "La mayor parte pagada",
          paid_minor: "Parte menor pagada",
          refund_verifying: "Verificando reembolso",
          refund_processing: "Procesando reembolso",
          risk_control: "Control de riesgos",
          refunded: "Reembolsado",
          partial_refund: "Parcialmente reembolsado",
          chargeback: "Contracargo",
          chargeback_reversed: "Contracargo invertido",
          dispute: "Disputa",
          dispute_reversed: "Disputa revertida",
          timeout: "Transacción agotada",
          payment_refuse: "Pago rechazado",
          payment_fail: "Pago fallido",
          refund_reverse: "Reembolso revertido",
          refund_refuse: "Reembolso rechazado",
          pay_close: "Cerrado",
          fail_sec: "Para la seguridad de su transacción, el pedido fue cerrado. Por favor, vuelva a enviarlo",
          invalid_cep: "CEP invalido",
          boleto_inst1: "¡Boleto generado!",
          boleto_inst2: "Puedes pagar en bancos, lotéricas, correos, supermercados o en línea",
          boleto_inst3:
            "Ya hemos enviado un correo electrónico con los detalles. Para descargar el archivo haga clic en el botón 'Guardar PDF'",
          deposit_upload_inst1: "¡Encantado de tenerte de vuelta!",
          deposit_upload_inst2:
            "Ya casi finalizamos, completa este breve formulario y cargue el comprobante de pago para terminar :)",
          "deposit_upload_inst3.1": "Ingrese ",
          "deposit_upload_inst3.2": "sus",
          "deposit_upload_inst3.3": " datos bancarios.",
          upload: "Subir",
          back: "Volver",
          wallet_inst1: "Escanear y pagar",
          wallet_inst2: "Puedes escanear y pagar usando:",
          wallet_inst3: "¿Cómo pagar?",
          wallet_inst4: "1. Abre la aplicación de la billetera en su dispositivo",
          "wallet_inst4.1": "1. Abre la aplicación del banco en su dispositivo",
          wallet_inst5: '2. Ve a la pestaña y haz click en "escanear" ',
          wallet_inst6: "3. Escanea el QR de pago y completa el pago",
          wallet_mob_ins1: "Ház clic y paga",
          wallet_mob_ins2: "Puedes hacer clic y pagar con:",
          wallet_mob_ins3: "1. Ház clic en el botón de abajo para ir a la APLICACIÓN ",
          "wallet_mob_ins3.1": " en tu teléfono",
          wallet_mob_ins4: "2. Procede a completar el pago",
          pago_method: "Banca móvil, QR (Yape, Plin), Agentes y Bodegas",
          pago_agent_title: "Agentes",
          pago_agent1: "1. Indica que vas realizar un pago a la empresa PAGOEFECTIVO.",
          pago_agent2: "2. Indica que realizarás el pago a PAGOEFECTIVO Soles.",
          "pago_agent3.1": "3. Indica el Código CIP: ",
          "pago_agent3.2": " y el importe a pagar.",
          pago_bank_title: "por Agencias Bancarias",
          pago_bank1: "1. Indica que vas realizar un pago a la empresa PAGOEFECTIVO.",
          pago_bank2: "2. Indica que realizarás el pago a PAGOEFECTIVO Soles",
          "pago_bank3.1": "3. Indica el Código CIP: ",
          "pago_bank3.2": " y el importe a pagar.",
          pago_online_title: "Pago vía Banca Móvil y por Internet",
          pago_online1: "1. Selecciona la opción Pago de Servicios.",
          pago_online2: "2. Busca y selecciona la institución/empresa PAGOEFECTIVO.",
          pago_online3: "3. Selecciona PAGOEFECTIVO Soles.",
          "pago_online4.1": "4. Ingresa tu código CIP: ",
          "pago_online4.2": " y sigue los pasos.",
          clap_cash_title: "¿Cómo pagar?",
          pay_cash_ins1:
            "Dirígete a tu punto de pago de efectivo más cercano y menciónale al cajero que vas hacer un pago PAYCASH con el código ",
          pay_cashgtm_ins1:
            "Dirígete al punto de pago de efectivo mas cercano, mencione al cajero que vas a hacer un pago al convenio {{key}} con el código ",
          clap_cash_ins1: "Dirígete a tu punto Klap EFECTIVO más cercano y solicita pagar con el código ",
          clap_cash_ins2: "Indícale a la persona del comercio que las opciones en la máquina son 2-8-1",
          clap_cash_ins3: "Paga y recibe tu comprobante",
          klap_store1: "Use",
          klap_store2: " este link ",
          klap_store3: "para buscar su tienda Klap más cercana",
          dexp1: "El pago se realiza en dos pasos:",
          dexp2: "1. Haz una transferencia, depósito o PIX",
          dexp3: "2. Ingrese el comprobante de pago",
          dexp4: "Nombre del banco:",
          dexp5: "Agencia",
          dexp6: "Cuenta corriente:",
          dexp7: "Titular:",
          dexp8: "Chave PIX:",
          dexp9: "Tipo de pago",
          dexp10: "Tipo de pago no válido",
          dexp11: "Longitud de 4 a 6 digitos, puede haber - en el medio",
          dexp12:
            "El formato correcto es de 5 a 12 dígitos. Comienza con un número, debe haber un - en el medio y el último dígito es un número o una x(X).",
          dexp13: "Subir comprobante de pago",
          dexp14: "Sube un archivo porfa",
          dexp15: "Transferencia entre el mismo banco",
          dexp16: "TED entre bancos",
          dexp17: "Depósito en efectivo",
          dexp18: "Depósito de sobres",
          dexp19: "Enviar prueba",
          dexp20: "Nombre completo",
          dexp21: "Documento cargado exitosamente. Espere el procesamiento y la confirmación de su pago.",
          account: "Cuenta",
          bank: "Banco",
          drag_notice: "Puedes arrastrar y soltar o hacer clic en el cuadro",
          fail_try_agin: "Algo salió mal. Por favor, vuelva a intentarlo.",
          fail_change_method: "Algo salió mal. Utilice otro método de pago.",
          fail_upload_again: "Algo salió mal. Intente cargar de nuevo",
          fail_upload: "Subida fallida",
          timed_out: "¡Se agotó el tiempo de espera de la solicitud de transacción!",
          no_channel: "No canal, contacte el comercio",
          subscription: "Te estás suscribiendo",
          subscription_billed: "se facturará",
          subscription_every: "cada",
          subscription_trial: "por período de prueba",
          subscription_then: ", después",
          trial_period: "periodo de prueba ",
          and: "y",
          day: "día",
          days: "días",
          week: "semana",
          weeks: "semanas",
          months: "meses",
          year: "año",
          years: "años",
          ref_no: "No de referencia: ",
          agree_code: "Convenio: ",
          id: "Cedula de identidad: ",
          amount: "Total a pagar: ",
          due_date: "Fecha de vencimiento",
          pay_points1: "Puede hacer clic",
          pay_points2: " aquí ",
          pay_points3: "para ver todos los puntos de pago.",
          pay_points_search: " para buscar el punto de pago más cercano.",
          payvalida_notice: "Recibo {{key}} generado!",
          payvalida_notice2: "Para realizar tu pago informa los siguientes dato en el punto {{key}} de tu preferencia:",
          select_country: "¡Por favor seleccione un país primero!",
          country: "País",
          invalid_country: "Seleccione un país",
          state_city: "Estado/Ciudad",
          invalid_state_city: "Estado/Ciudad inválido",
          refresh: "Recargar",
          refresh_notice:
            "La página se actualizará automáticamente en 30 segundos, de lo contrario, haz clic en el botón de abajo.",
          download: "Descargar",
          network_err: "Error de red, por favor, inténtelo de nuevo.",
          pix_notice:
            "Por favor evite usar PayPal para pagar su pedido de PIX por ahora. Elegir hacerlo aumentará en gran medida el tiempo de aprobación de su pedido.",
          address_billing: "Dirección de envio:",
          address_delivery: "Dirección de entrega:",
          address_same: "Igual a la dirección de facturación",
          process_page1:
            "Listo, recibirás una confirmación de pago en tu correo electrónico mientras procesamos tu pago.",
          process_page2: "Puede cerrar esta página ahora.",
          barcode_step1:
            "Dirígete a tu punto de pago de efectivo más cercano y menciónale al cajero que vas hacer un pago con el código ",
          "barcode_step1.1":
            "Dirígete a tu punto de {{key}} más cercano y menciónale al cajero que vas hacer un pago PAYCASH con el código ",
          payment_point: "punto de pago de efectivo",
          bank_list: "Lista de bancos",
          bank_select: "Seleccione un banco",
          agent: "Agencia",
          agent_invalid: "Agencia inválida",
          agent_select: "Seleccionar un agente",
          account_number: "Número de cuenta",
          account_invalid: "Número de cuenta inválido",
          checking_account: "Cuenta corriente",
          saving_account: "Cuenta de ahorros",
          master_account: "Cuenta administradora",
          pb_ins:
            "Ingrese a la aplicación Player's Bank, toque Pix, elija Pay QR Code y escanee el código a continuación.",
          pb_mob_ins: "Toca el botón de abajo y finaliza el pago en el APP.",
          lp_ins1: "El pago se puede realizar mediante transferencia o depósito bancario.",
          lp_ins2: "Fecha de caducidad: ",
          lp_ins3: "Número de referencia: ",
          lp_ins4: "Información de la cuenta:",
          lp_ins5: "Nombre del banco",
          account_type: "Tipo de cuenta",
          account_type_err: "Seleccione un tipo de cuenta",
          lp_ins7: "Número de cuenta",
          lp_ins8: "Nombre del Beneficiario",
          lp_ins9: "Tipo de Documento",
          lp_ins10: "Identificación del documento",
          lp_ins11: "El número de referencia debe ser exactamente el mismo.",
          trsf1: "Abra la aplicación de su institución financiera",
          trsf2: "Vaya a: Servicios > Transacciones > Transfiya",
          trsf3: "Seleccione la cuenta con la que desea pagar",
          trsf4: "Paga a esta cuenta: ",
          trsf5: "Recuerda transferir el monto exacto de ",
          trsf6: ", de lo contrario la transacción fallará",
          spei_key: "Esta clave solo se puede usar una vez",
          spei_clabe: "CLABE interbancária",
          spei_bank: "Banco de destino",
          spei_recipient: "Beneficiário",
          pb_qr: "Código QR Player's Bank",
          pb_card: "Tarjeta Player's Bank",
          pci_notice: "Todas las transacciones están garantizadas de forma segura y cumplen con el protocolo PCI DSS.",
          "payment.redirect.notice1": "Usted será redireccionado a la página del comercio en {{key}} segundos.",
          "payment.redirect.notice2": "Haga click ",
          "payment.redirect.notice3": "aquí para",
          "payment.redirect.notice4": " cancelar.",
          service_name: "Nombre del servicio",
          pay_amount: "Importe a pagar",
          pay_code: "Codigo de pago",
          mon_bankMobile: "Banca Móvil",
          mon_bankWeb: "Banca Web",
          mon_bank: "Ventanilla",
          mon_agent: "Agentes",
          mon_chooseBank: "1. Escoge el banco o agente",
          mon_chooseMethod: "2. Escoge el método de pago",
          address: "Dirección",
          invalid_address: "Dirección inválida",
          pay_or_fail: "Recuerda realizar el pago en 15 min, de lo contrario la transacción fallará",
          ben_info: "Información de beneficiario",
          bank_acc_no: "Número de cuenta bancaria",
          bank_acc_no_err: "Número de cuenta bancaria inválido",
          sec_code: "Código de seguridad",
          sec_code_err: "Código de seguridad inválido",
          cash_ins1: "Ingresa a sección de pagos y busca por nombre a Paycash",
          cash_ins2: "Ingresa los últimos {{key}} dígitos del número de referencia y confirma el pago",
          cash_ins3: "Ingresa el token cliente y espera la confirmación de la operación",
          cash_ins4: "Ingresa los {{key}} dígitos del número de referencia y confirma el pago",
          fill_all: "Llene todas las informaciones",
          digital_bank1: "Selecciona Servicios, Recaudaciones",
          digital_bank2: "Pago de servicios básicos, impuestos y catálogo 2. Otras recaudaciones PayCash",
          digital_bank3: "Ingresa referencia y monto del pago",
          bncr_paycash0:
            "Dirígete al punto de pago BN Servicios más cercano y solicita al cajero realizar un pago PayCash con el código ",
          bncr_paycash1: "Ingresa al Internet Banking del Banco Nacional con su identificación y contraseña",
          bncr_paycash2: "Realiza búsqueda por nombre: Paycash e ingresa la referencia de 16 dígitos",
          bncr_paycash3: "Se confirma la orden, selecciona la forma de pago y espera la confirmación",
          digital_banking: "Banca Digital",
          bncr_cash1:
            "Dirígete al punto de pago BN Servicios más cercano y solicita al cajero realizar un pago {{key}} con el No. de referencia brindado arriba",
          bncr_cash2: "Paga y recibe tu comprobante",
          bncr_bank1: "Ingresa al Internet Banking del Banco Nacional con su identificación y contraseña",
          bncr_bank2: "Realiza búsqueda por nombre: Payválida e ingresa el no. de referencia",
          bncr_bank3: "Se confirma la orden, selecciona la forma de pago y espera la confirmación",
          edit_address: "Editar dirección",
          order_expired: "Pedido caducado",
          exp_date_placeholder: "MM/AA",
          verification_code: "Código de verificación",
          redirecting: "Redireccionando...",
          20000: "Servicio actualmente no disponible",
          40000: "Transacción falló",
          40002: "Transacción falló",
          40012: "El orden no existe",
          40013: "Orden finalizada",
          40014: "La información del pedido es inconsistente",
          40015: "Se superó el valor máximo (impuestos incluidos)",
          40101: "Tiempo limite de la orden",
          40201: "Falló al cargar",
          40301: "El banco está ocupado",
          "SYS-0001": "Su pago fue rechazado. Elija otro método de pago. Recomendamos métodos de pago en efectivo.",
          "SYS-0002": "Para la seguridad de su transacción, el pedido está cerrado, vuelva a enviarlo",
          "SYS-0003": "Elija el valor más alto",
          "SYS-0004": "Proporcione la dirección de correo electrónico válida.",
          "CC-0001": "Pago denegado. El nombre y el CPF no coinciden. Ingrese la información correcta.",
          "CC-0002": "Token de tarjeta no válido",
          "CC-0003":
            "Estamos procesando el pago. Dentro de 2 días hábiles te informaremos por correo electrónico del resultado.",
          "CC-0004":
            "Estamos procesando el pago. Dentro de 2 días hábiles te informaremos por correo electrónico si ha sido aprobado o si necesitamos más información.",
          "CC-0005": "Verifique el número de tarjeta.",
          "CC-0006": "Verifique la fecha de vencimiento",
          "CC-0007": "Verifique los datos.",
          "CC-0008": "Verifique el código de seguridad.",
          "CC-0009": "No pudimos procesar su pago.",
          "CC-0010": "Debe autorizar a la Tarjeta a pagar el monto a Pagsmile.",
          "CC-0011": "Tarjeta de llamada para activar su tarjeta. El teléfono está en el reverso de su tarjeta.",
          "CC-0012": "No pudimos procesar su pago.",
          "CC-0013":
            "Ya realizó un pago con esta cantidad. Si necesita pagar nuevamente, use otra tarjeta u otra forma de pago",
          "CC-0014": "Su pago ha sido rechazado. Elija otro método de pago. Recomendamos métodos de pago en efectivo.",
          "CC-0015": "La tarjeta tiene saldo insuficiente.",
          "CC-0016": "La Tarjeta no procesa pagos en cuotas",
          "CC-0017": "Ha alcanzado el límite de intentos permitido. Elija otra tarjeta u otra forma de pago.",
          "CC-0018": "La tarjeta no procesó su pago.",
          70001: "No podemos procesar su pago.",
          80004: "El usuario está en la lista negra",
          "70001-10001": "El CPF ingresado no es válido, ingrese otro",
          "70001-10002": "Esta operación no se puede procesar. Póngase en contacto con atención al cliente",
          "70001-10003": "Esta operación no se puede procesar. Póngase en contacto con atención al cliente",
          "70001-10004": "Esta operación no se puede procesar. Póngase en contacto con atención al cliente",
          "70001-20001": "Superado el límite de transacciones",
          "70001-99999": "Esta operación no se puede procesar. Póngase en contacto con atención al cliente",
        },
      },
      pt: {
        translation: {
          ...termsOfServicePt,
          ...privcayPt,
          en: "Inglês",
          es: "Espanhol",
          pt: "Português",
          ry: "Russo",
          ar: "Árabe",
          faq: "Perguntas Frequentes",
          efecty_faq1q: "O que é Efecty?",
          efecty_faq1a:
            "Efecty é uma empresa com sede na Colômbia que oferece uma variedade de métodos de pagamento, incluindo pedidos de dinheiro, pagamentos, recargas e cobranças em todo o país. Os clientes podem pagar online e pagar mais tarde em qualquer um dos 8000 pontos de atendimento no país.",
          efecty_faq2q: "Como posso encontrar um lugar para pagar em dinheiro?",
          efecty_faq2a: "Vá para https://mapapap.efecty.com.co/pagos-personas e pesquise seu endereço",
          efecty_faq3q: "Como saber se meu pedido foi bem sucedido?",
          efecty_faq3a:
            "As compras são aprovadas imediatamente e você vai receber um email confirmando seu pagamento.  Se você tiver dúvidas sobre a compra que está fazendo, ligue para nós nas linhas de atendimento ao cliente.",
          khipu_faq1q: "O que é Khipu?",
          khipu_faq1a:
            "Khipu é uma maneira de receber pagamentos com transferência que funciona em qualquer dispositivo, responsavelmente, e em qualquer navegador.",
          khipu_faq2q: "Como funciona?",
          khipu_faq2a:
            "Basta inserir suas informações khipu (número do cartão e senha) e concluir a transação no site ou aplicativo do seu banco.",
          khipu_faq3q: "Como saber se meu pedido foi bem sucedido?",
          khipu_faq3a:
            "As compras são aprovadas imediatamente e você vai receber um email confirmando seu pagamento.  Se você tiver dúvidas sobre a compra que está fazendo, ligue para nós nas linhas de atendimento ao cliente.",
          baloto_faq1q: "¿Cómo pagar con VIA Baloto?",
          baloto_faq1a:
            "Dígale los datos al cajero en el punto VIA Baloto de su preferencia y verifique si todo está igual que en el recibo. Este cupón solo es válido para este pedido. Si realiza dos pagos, se cancelarán.",
          sured_faq1q: "¿Cómo pagar con Su Red?",
          sured_faq1a:
            "Dígale los datos al cajero en el punto Su Red de su preferencia y verifique si todo está igual que en el recibo. Este cupón solo es válido para este pedido. Si realiza dos pagos, se cancelarán.",
          gana_faq1q: "¿Cómo pagar con Gana?",
          gana_faq1a:
            "Dígale los datos al cajero en el punto Gana de su preferencia y verifique si todo está igual que en el recibo. Este cupón solo es válido para este pedido. Si realiza dos pagos, se cancelarán.",
          help_chat: "Chat de ajuda",
          okay: "Certo!",
          select_notice: "Selecionar método de pagamento",
          instructions: "Instruções",
          videos: "Videos",
          pictures: "Fotos",
          text: "Texto",
          online_inst1: "Log on to online banking as usual.",
          online_inst2: "Enter your 6 digit password.",
          online_inst3: "Select Payment and Services and companies category. Then enter in the search “SafetyPay”.",
          online_inst4:
            "Selecting this option will request the transaction number and the amount that was provided by the merchant.",
          online_inst5: "Confirm the transaction by entering your electronic key (token) and you are done!",
          payment_method: "Método de pagamento",
          online_banking: "Online Banking",
          cash_payments: "Pagamento em dinheiro",
          bank_agent: "Escolha seu banco ou agente",
          filling_details: "Detalhes pessoais",
          fill_info: "Preencha as informações",
          details: "Detalhes",
          name: "Nome",
          email: "Email",
          TIN: "CPF",
          phone: "Número de celular",
          time_remain: "Tempo restante:",
          trx_id: "ID da transação",
          hide: "Esconder",
          cash: "Dinheiro",
          show: "Mostrar",
          product_description: "Descrição do produto",
          order_value: "Valor do pedido",
          fee_102: "Taxa Bancária",
          notice_102: "",
          fee_101: "Impostos",
          notice_101: "Note que com base na Lei nº 27.430, ao valor desta compra será acrescido um IVA de {{key}}.",
          fee_100: "Outras taxas",
          notice_100: "",
          fee_104: "Lucro",
          notice_104:
            "De acordo com a Lei 27.541 e o Decreto 99/2019, pode incluir um percentual de 8% ou 30% sobre o valor da transação em pesos, dependendo do tipo de bens/serviços adquiridos.",
          fee_105: "Ganancias",
          notice_105:
            "Observe que com base na Resolução Geral RG 4815/2020, serão acrescidos 35% para a compra de moeda estrangeira e 45% para as demais operações abrangidas pelo PAIS.",
          discount: "Desconto",
          confirm: "Confirmar",
          return: "Return",
          attention: "ATENÇÃO: Por favor veja as informações abaixo.",
          total: "Total",
          privacy_policy_error:
            "Você deve ler e aceitar os Termos de Uso e a Política de Privacidade para concluir sua compra.",
          privacy_policy1: "Li e concordo com os",
          privacy_policy2: " Termos de Uso",
          privacy_policy3: " e",
          privacy_policy4: " Política de Privacidade",
          privacy_policy5: " do Pagsmile.",
          invalid_name: "Nome inválido",
          invalid_TIN: "ID inválido",
          invalid_email: "Email inválido",
          invalid_phone: "Número de telefone inválido",
          payment_inst: "Instruções de pagamento:",
          payment_inst_top:
            "Registramos seu pedido e estamos aguardando o pagamento para começar a entrega. Só falta um pouquinho!",
          payment_ticket: "Tíquete de Pagamento",
          payment_code: "Código de pagamento",
          pay: "Pagar:",
          pay2: "Pagar",
          processing: "Processando",
          processing_notice: "A transação está em andamento, é rapidinho. Obrigado por usar Pagsmile!",
          refund_in_process: "O reembolso está em andamento, é rapidinho. Obrigado por usar Pagsmile!",
          success: "Pagamento feito com sucesso",
          success_result: "Sucesso",
          success_notice:
            "Você pagou o pedido com sucesso. Se quiser, pode voltar para a página do vendedor. Obrigado por usar Pagsmile!",
          cancelled: "Cancelado",
          order_timeout: "O prazo para o seu pagamento terminou, volte à página do vendedor para fazer um novo pedido.",
          failed: "Falhou",
          copied: "Copiado com sucesso",
          copy: "Copiar",
          visit_us: "Para saber mais sobre nós, visite",
          fail_notice: "É possível que alguma informação esteja errada, tente novamente ou fale com a gente",
          fail_query: "Quer tentar de novo?",
          yes: "Sim, quero",
          help_me: "Ajuda",
          order: "Pedido",
          back_shop: "De volta às compras",
          credit_debit_card: "Cartão de Crédito/Débito",
          credit_card: "Cartão de Crédito",
          debit_card: "Cartão de Débito",
          oxxo: "Pago en efectivo OXXO",
          wallet: "Carteira Digital",
          cardholder_name: "Nome completo do titular do cartão",
          card_no: "Número do cartão",
          invalid_card_no: "Número do cartão inválido",
          exp_year: "Ano de valid.",
          exp_date: "Data de validade",
          invalid_exp_date: "Data de validade inválido",
          invalid_year: "Selecione um ano",
          exp_month: "Mês de valid.",
          month: "mês",
          invalid_month: "Selecione um mês",
          state: "Estado",
          invalid_state: "Selecione um Estado",
          city: "Cidade",
          invalid_city: "Selecione uma cidade",
          postal_code: "Código postal",
          invalid_postal_code: "CEP inválido",
          street: "Rua",
          invalid_street: "Rua inválida",
          number: "Número",
          invalid_number: "Número inválido",
          source: "Site ou nome do APP onde você comprou o produto",
          invalid_source: "Inválido",
          source_notice: "Se as informações inseridas estiverem incorretas, o pagamento do seu pedido pode falhar",
          todito_notice: "Insira suas informações do Todito Cash abaixo:",
          "checkout_inst1.1": "Caro ",
          "checkout_inst1.2": "usuário,",
          "checkout_inst1.3": " pague ",
          "checkout_inst1.4": " para finalizar sua compra.",
          "checkout_inst2.1": "Leia o código QR para ir para",
          "checkout_inst2.1.1": "Clique no botão para ir para",
          "checkout_inst2.2": "APP e conclua o pagamento.",
          go_to: "Vá para ",
          checkout_inst3: "1. Vá para a loja mais próxima",
          checkout_inst4: "2. Informe ao caixa que você tem um pagamento do Pagsmile e mostre este número: ",
          share: "Compartilhar",
          save_pdf: "Salvar PDF",
          checkout_inst5: "Abra o aplicativo do seu banco e insira as informações abaixo",
          checkout_inst6: "Insira este número de referência:",
          register_info: "Informações para registrar a conta",
          installments: "Parcelamento",
          invalid_cvv: "CVC invalido",
          invalid_PIN: "PIN invalido",
          invalid_NIP: "NIP invalido",
          paid_major: "Maior parte paga",
          paid_minor: "Parte secundária paga",
          refund_verifying: "Verificando reembolso",
          refund_processing: "Processando reembolso",
          risk_control: "Controle de riscos",
          refunded: "Reembolsado",
          partial_refund: "Parcialmente reembolsado",
          chargeback: "Estorno",
          chargeback_reversed: "Estorno revertido",
          dispute: "Disputa",
          dispute_reversed: "Disputa revertida",
          timeout: "Tempo limite da transação esgotado",
          payment_refuse: "Pagamento recusado",
          payment_fail: "Pagamento falhou",
          refund_reverse: "Reembolso revertido",
          refund_refuse: "Reembolso recusado",
          pay_close: "Fechado",
          fail_sec: "Para a segurança da sua transação, o pedido foi encerrado. Por favor tente novamente",
          invalid_cep: "CEP invalido",
          boleto_inst1: "Boleto gerado!",
          boleto_inst2: "Você pode pagar em bancos, lotéricas, Correios, supermercados ou online.",
          boleto_inst3:
            "Já enviamos um e-mail com os detalhes e você também pode salvar um arquivo clicando no botão 'Salvar PDF' abaixo.",
          deposit_upload_inst1: "É bom ter você de volta!",
          deposit_upload_inst2:
            "Estamos quase lá, basta preencher este pequeno formulário e fazer upload do comprovante de pagamento para finalizar :)",
          "deposit_upload_inst3.1": "Por favor, insira os ",
          "deposit_upload_inst3.2": "seus",
          "deposit_upload_inst3.3": " dados bancários.",
          upload: "Carregar",
          back: "Voltar",
          wallet_inst1: "Escaneie e pague",
          wallet_inst2: "Você pode escanear e pagar usando:",
          wallet_inst3: "Como pagar?",
          wallet_inst4: "1. Abra o aplicativo de carteira no seu dispositivo",
          "wallet_inst4.1": "1. Abra o aplicativo do banco no seu dispositivo",
          wallet_inst5: '2. Abra o recurso "escanear"',
          wallet_inst6: "3. Escaneie o QR de pagamento e conclua o pagamento",
          wallet_mob_ins1: "Clique e pague",
          wallet_mob_ins2: "Você pode clicar e pagar usando:",
          wallet_mob_ins3: "1. Clique no botão abaixo para ir para o APP ",
          "wallet_mob_ins3.1": " em seu telefone",
          wallet_mob_ins4: "2. Prossiga para concluir o pagamento",
          pago_method: "Banco Móvel, QR (Yape, Plin), Agentes e Armazéns",
          pago_agent_title: "Agentes",
          pago_agent1: "1. Indique que vai efetuar um pagamento à empresa PAGOEFECTIVO.",
          pago_agent2: "2. Indique que fará o pagamento a PAGOEFECTIVO Soles.",
          "pago_agent3.1": "3. Indique o Código CIP: ",
          "pago_agent3.2": " e o valor a pagar.",
          pago_bank_title: "por Agências Bancárias",
          pago_bank1: "1. Indique que vai efetuar um pagamento à empresa PAGOEFECTIVO.",
          pago_bank2: "2. Indique que você fará o pagamento para PAGOEFECTIVO Soles",
          "pago_bank3.1": "3. Indique o Código CIP: ",
          "pago_bank3.2": " e o valor a pagar.",
          pago_online_title: "Transferência Online",
          pago_online1: "1. Selecione a opção Pagamento por Serviços.",
          pago_online2: "2. Pesquise e selecione a instituição / empresa PAGAMENTO EM DINHEIRO.",
          pago_online3: "3. Selecione Solas de PAGAMENTO EM DINHEIRO.",
          "pago_online4.1": "4. Digite seu código CIP: ",
          "pago_online4.2": " e siga as etapas.",
          clap_cash_title: "Como pagar?",
          pay_cash_ins1:
            "Vá ao local que você escolheu e diga ao caixa que quer fazer um pagamento para PAYCASH com o código ",
          pay_cashgtm_ins1:
            "Vá ao ponto de pagamento à vista mais próximo, mencione ao caixa que vai efetuar o pagamento do convênio {{key}} com o código ",
          clap_cash_ins1: "Vá até o ponto Klap EFECTIVO mais próximo e solicite o pagamento com o código ",
          clap_cash_ins2: "Diga ao comerciante que as opções da máquina são 2-8-1",
          clap_cash_ins3: "Pague e peça seu recibo",
          klap_store1: "Use",
          klap_store2: " este link ",
          klap_store3: "para procurar a loja Klap mais próxima",
          dexp1: "O pagamento é feito em dois passos:",
          dexp2: "1. Faça uma transferência, depósito ou PIX",
          dexp3: "2. Insira o comprovante de pagamento",
          dexp4: "Nome do banco:",
          dexp5: "Agência",
          dexp6: "Conta corrente:",
          dexp7: "Titular:",
          dexp8: "Chave PIX:",
          dexp9: "Tipo de Pagamento",
          dexp10: "Tipo de pagamento inválido",
          dexp11: "O formato correto tem de 4 a 6 dígitos (pode haver - no meio)",
          dexp12:
            "O formato correto tem 5 a 12 dígitos. Começa com um número, deve haver um - no meio, e o último dígito é um número ou x(X)",
          dexp13: "Carregar comprovante de pagamento",
          dexp14: "Por favor carregue um arquivo",
          dexp15: "Transferência no mesmo banco",
          dexp16: "TED entre bancos",
          dexp17: "Depósito em caixa",
          dexp18: "Depósito em envelope",
          dexp19: "Enviar comprovante",
          dexp20: "Nome completo",
          dexp21: "Arquivo enviado com sucesso. Aguarde o processamento e a confirmação do seu pagamento.",
          account: "Conta",
          bank: "Banco",
          drag_notice: "Você pode arrastar e soltar ou clicar na caixa",
          fail_try_agin: "Alguma coisa deu errado. Por favor tente outra vez.",
          fail_change_method: "Algo deu errado. Use outro método de pagamento.",
          fail_upload_again: "Algo deu errado. Por favor, tente fazer o upload novamente",
          fail_upload: "Falha no upload",
          timed_out: "A solicitação de transação expirou!",
          no_channel: "Sem canal, entre em contato com a loja",
          subscription: "Você está assinando",
          subscription_billed: "sendo cobrado",
          subscription_every: "cada",
          subscription_trial: "pelo período de teste",
          subscription_then: ", depois",
          trial_period: "período de teste",
          and: "e",
          day: "dia",
          days: "dias",
          week: "semana",
          weeks: "semanas",
          months: "meses",
          year: "ano",
          years: "anos",
          ref_no: "No de referencia: ",
          agree_code: "Convenio: ",
          id: "Cedula de identidad: ",
          amount: "Total a pagar: ",
          due_date: "Fecha de vencimiento",
          pay_points1: "Você pode clicar",
          pay_points2: " aqui ",
          pay_points3: "para ver todos os pontos de pagamento.",
          pay_points_search: " para pesquisar o ponto de pagamento mais próximo.",
          payvalida_notice: "Recibo {{key}} generado!",
          payvalida_notice2: "Para realizar tu pago informa los siguientes dato en el punto {{key}} de tu preferencia:",
          select_country: "Selecione um país primeiro!",
          country: "País",
          invalid_country: "Selecione um pais",
          state_city: "Estado/Cidade",
          invalid_state_city: "Estado/Cidade inválido",
          refresh: "Recarregar",
          refresh_notice:
            "A página será atualizada automaticamente em 30 segundos, caso contrário, clique no botão abaixo.",
          download: "Download",
          network_err: "Erro de rede, por favor, tente novamente.",
          pix_notice:
            "Por favor evite usar o PayPal para efetuar o pagamento de seu pedido PIX. Escolhendo por usá-lo irá aumentar considerávelmente o tempo de aprovação do seu pedido.",
          address_billing: "Endereço de cobrança:",
          address_delivery: "Endereço de entrega:",
          address_same: "O mesmo endereço de cobrança",
          process_page1:
            "Pronto, você vai receber uma confirmação de pagamento no seu e-mail enquanto processamos sua compra.",
          process_page2: "Você pode fechar esta página agora.",
          barcode_step1: "Vá até o ponto pagamento em dinheiro mais próximo e solicite o pagamento com o código ",
          "barcode_step1.1":
            "Vá até o ponto de pagamento {{key}} mais próximo e informe ao caixa que deseja fazer um pagamento ao PAYCASH com o código ",
          payment_point: "ponto pagamento em dinheiro",
          bank_list: "Lista de banco",
          bank_select: "Selecione um banco",
          agent: "Agência",
          agent_invalid: "Agência inválida",
          agent_select: "Selecione um agente",
          account_number: "Número da conta",
          account_invalid: "Número da conta inválido",
          checking_account: "Cuenta corriente",
          saving_account: "Cuenta de ahorros",
          master_account: "Cuenta administradora",
          pb_ins: "Entre no app Player's Bank, toque no Pix, escolha Pagar QR Code e escaneie o código abaixo.",
          pb_mob_ins: "Toque no botão abaixo e finalize o pagamento pelo banco que joga junto.",
          lp_ins1: "O Pagamento pode ser feito por transferência bancária ou depósito.",
          lp_ins2: "Data de validade: ",
          lp_ins3: "Número de referência:",
          lp_ins4: "Informação da conta:",
          lp_ins5: "Nome do banco",
          account_type: "Tipo de conta",
          account_type_err: "Selecione um tipo de conta",
          lp_ins7: "Número da conta",
          lp_ins8: "Nome do beneficiário",
          lp_ins9: "Tipo de documento",
          lp_ins10: "ID do documento",
          lp_ins11: "O número de referência deve ser exatamente o mesmo.",
          trsf1: "Abra seu aplicativo de instituições financeiras",
          trsf2: "Vá para: Serviços > Transações > Transfiya",
          trsf3: "Selecione a conta com a qual deseja pagar",
          trsf4: "Pague nesta conta: ",
          trsf5: "Lembre-se de transferir o valor exato de ",
          trsf6: ", caso contrário a transação falhará",
          spei_key: "Esta chave só pode ser usada uma vez",
          spei_clabe: "CLABE interbancária",
          spei_bank: "Banco destino",
          spei_recipient: "Beneficiário",
          pb_qr: "QR Code Player's Bank",
          pb_card: "Cartão Player's Bank",
          pci_notice: "Todas as transações são garantidas com segurança e em conformidade com o protocolo PCI DSS.",
          "payment.redirect.notice1": "Você será redirecionado para a página do lojista em {{key}} segundos.",
          "payment.redirect.notice2": "Clique ",
          "payment.redirect.notice3": "aqui para",
          "payment.redirect.notice4": " cancelar.",
          service_name: "Serviço",
          pay_amount: "Valor",
          pay_code: "Código de pagamento",
          mon_bankMobile: "Celular",
          mon_bankWeb: "Computador",
          mon_bank: "Banco",
          mon_agent: "Loja",
          mon_chooseBank: "1. Escolha o lugar que quer pagar",
          mon_chooseMethod: "2. Escolha a forma de pagamento",
          address: "Endereço",
          invalid_address: "Endereço inválido",
          pay_or_fail: "Lembre-se de fazer o pagamento em 15 minutos, caso contrário, a transação falhará",
          ben_info: "Informação do recebedor",
          bank_acc_no: "Número da conta bancária",
          bank_acc_no_err: "Número da conta bancária inválido",
          sec_code: "Código de segurança",
          sec_code_err: "Código de segurança inválido",
          cash_ins1: "Entre na seção de pagamento e procure por Paycash pelo nome",
          cash_ins2: "Insira os últimos {{key}} dígitos do número de referência e confirme o pagamento",
          cash_ins3: "Digite o token do cliente e aguarde a confirmação da operação",
          cash_ins4: "Insira os {{key}} dígitos do número de referência e confirme o pagamento",
          fill_all: "Preencha todos os campos",
          digital_bank1: "Selecione Serviços, Coleções",
          digital_bank2: "Pagamento de serviços básicos, impostos e catálogo 2. Outras cobranças PayCash",
          digital_bank3: "Insira a referência e o valor do pagamento",
          bncr_paycash0:
            "Vá ao ponto de pagamento do BN Servicios mais próximo e peça ao caixa para efetuar o pagamento em PayCash com o código ",
          bncr_paycash1: "Entre no Internet Banking do Banco Nacional com sua identificação e senha",
          bncr_paycash2: "Faça a busca pelo nome: Paycash e digite número de referência de 16 dígitos",
          bncr_paycash3: "Pagamento confirmado, selecione a forma de pagamento e aguarde a confirmação",
          digital_banking: "Banco digital",
          bncr_cash1:
            "Vá ao ponto de pagamento do BN Servicios mais próximo e solicite ao caixa que efetue um pagamento {{key}} com o número de referência fornecido acima",
          bncr_cash2: "Pague e receba seu voucher",
          bncr_bank1: "Entre no Internet Banking do Banco Nacional com sua identificação e senha",
          bncr_bank2: "Realize a busca pelo nome: Payválida e digite o número de referência",
          bncr_bank3: "O número de referência será confirmado, selecione a forma de pagamento e aguarde a confirmação",
          edit_address: "Editar endereço",
          order_expired: "Pedido expirado",
          exp_date_placeholder: "MM/AA",
          verification_code: "Código de verificação",
          redirecting: "Redirecionando...",
          20000: "Serviço atualmente indisponível",
          40000: "A transação falhou",
          40002: "A transação falhou",
          40012: "Pedido não existe",
          40013: "Pedido terminou",
          40014: "As informações do pedido são inconsistentes",
          40015: "Valor máximo (incluindo taxas) excedido",
          40101: "Tempo limite do pedido",
          40201: "Falha ao carregar",
          40301: "Banco esta ocupado",
          "SYS-0001":
            "Seu pagamento foi recusada. Escolha outra forma de pagamento. Recomendamos meios de pagamento em dinheiro.",
          "SYS-0002": "Para a segurança da sua transação, a ordem está encerrada, por favor reenvie-o.",
          "SYS-0003": "Por favor escolha o valor mais alto.",
          "SYS-0004": "Por favor forneça o email válido.",
          "CC-0001": "Pagamento negado. Nome e CPF não são correspondentes. Por favor insira a informação correta.",
          "CC-0002": "Token de cartão inválido",
          "CC-0003": "Estamos processando o pagamento. Em até 2 dias úteis informaremos por e-mail o resultado.",
          "CC-0004":
            "Estamos processando o pagamento. Em até 2 dias úteis informaremos por e-mail se foi aprovado ou se precisamos de mais informações.",
          "CC-0005": "Confira o número do cartão.",
          "CC-0006": "Confira a data de validade.",
          "CC-0007": "Confira os dados.",
          "CC-0008": "Confira o código de segurança.",
          "CC-0009": "Não conseguimos processar seu pagamento.",
          "CC-0010": "Você deve autorizar ao Cartão o pagamento do valor ao Pagsmile.",
          "CC-0011": "Ligue para o Cartão para ativar seu cartão. O telefone está no verso do seu cartão.",
          "CC-0012": "Não conseguimos processar seu pagamento.",
          "CC-0013":
            "Você já efetuou um pagamento com esse valor. Caso precise pagar novamente, utilize outro cartão ou outra forma de pagamento.",
          "CC-0014":
            "Seu pagamento foi recusado. Escolha outra forma de pagamento. Recomendamos meios de pagamento em dinheiro.",
          "CC-0015": "O Cartão possui saldo insuficiente.",
          "CC-0016": "O Cartão não processa pagamentos parcelados.",
          "CC-0017": "Você atingiu o limite de tentativas permitido. Escolha outro cartão ou outra forma de pagamento.",
          "CC-0018": "O Cartão não processou seu pagamento.",
          70001: "Não conseguimos processar seu pagamento.",
          80004: "O usuário está na lista negra",
          "70001-10001": "O CPF digitado é inválido, digite outro",
          "70001-10002": "Esta transação não pode ser processada. Entre em contato com o suporte ao cliente",
          "70001-10003": "Esta transação não pode ser processada. Entre em contato com o suporte ao cliente",
          "70001-10004": "Esta transação não pode ser processada. Entre em contato com o suporte ao cliente",
          "70001-20001": "Limite de transação excedido",
          "70001-99999": "Esta transação não pode ser processada. Entre em contato com o suporte ao cliente",
        },
      },
      ru: {
        translation: {
          en: "Английский",
          es: "Испанский",
          pt: "Португальский",
          ru: "Pусский",
          ar: "Aрабский",
          payment_method: "Способ оплаты",
          filling_details: "Детали",
          name: "Имя",
          invalid_name: "Некорректное имя",
          email: "Электронная почта",
          invalid_email: "Неверный адрес электронной почты",
          phone: "Номер телефона",
          invalid_phone: "Неверный номер телефона",
          time_remain: "Оставшееся время:",
          trx_id: "Номер транзакции:",
          product_description: "Описание продукта:",
          order_value: "Стоимость заказа:",
          total: "Всего",
          hide: "Скрыть",
          processing_notice: "Транзакция в процессе, подождите немного. Спасибо за использование Pagsmile!",
          refund_in_process: "Возврат средств в процессе, подождите немного. Спасибо за использование Pagsmile!",
          success: "Платеж прошел успешно",
          fail_query: "Хотите попробовать еще раз?",
          yes: "Да",
          help_me: "Помощь",
          back_shop: "Назад к покупкам",
          fee_102: "банковская комиссия",
          fee_101: "Налоговый сбор",
          fee_100: "Другие сборы",
          discount: "Скидка",
          details: "Детали",
          confirm: "Подтверждать",
          privacy_policy1: "Я ознакомился и согласен с",
          privacy_policy2: " условиями использования",
          privacy_policy3: " и",
          privacy_policy4: " политикой конфиденциальности",
          privacy_policy5: " Pagsmile",
          pci_notice: "Все транзакции надежно гарантированы и соответствуют протоколу PCI DSS.",
          "payment.redirect.notice1": "Вы будете перенаправлены на страницу продавца через {{key}} секунд.",
          "payment.redirect.notice2": "нажмите ",
          "payment.redirect.notice3": "здесь,",
          "payment.redirect.notice4": " чтобы отменить.",
          refresh: "Oбновите",
          refresh_notice:
            "Страница будет обновлена автоматически через 30 секунд, в противном случае, пожалуйста, нажмите на кнопку ниже.",
          network_err: "Ошибка сети, попробуйте еще раз.",
          fail_try_agin: "Что-то пошло не так. Пожалуйста, повторите попытку позже.",
          fail_change_method: "Что-то пошло не так. Пожалуйста, используйте другой способ оплаты.",
          timed_out: "Время запроса на транзакцию истекло!",
          no_channel: "Нет канала, пожалуйста, свяжитесь с продавцом",
          bank_agent: "Выберите Банк/Агента",
          order_timeout: "Срок платежа истек, пожалуйста, вернитесь на страницу продавца, чтобы сделать новый заказ",
          edit_address: "Редактировать адрес",
          "70001-10001": "Введенный CPF недействителен, введите другой",
          "70001-10002": "Эта транзакция не может быть обработана. Связаться со службой поддержки",
          "70001-10003": "Эта транзакция не может быть обработана. Связаться со службой поддержки",
          "70001-10004": "Эта транзакция не может быть обработана. Связаться со службой поддержки",
          "70001-20001": "Превышен лимит транзакции",
          "70001-99999": "Эта транзакция не может быть обработана. Связаться со службой поддержки",
          20000: "Сейчас услуга недоступна",
          40000: "Транзакция не удалась",
          40002: "Транзакция не удалась",
          40012: "Заказ не существует",
          40013: "Заказ завершен",
          40014: "Информация о заказе несовместима",
          40015: "Превышена максимальная сумма (включая комиссии)",
          40101: "Тайм-аут заказа",
          40201: "Не удалось загрузить",
          40301: "Банк занят",
          70001: "Мы не можем обработать ваш платеж.",
          80004: "Пользователь занесен в черный список",
        },
      },
      ar: {
        translation: {
          en: "الإنجليزية",
          es: "الإسبانية",
          pt: "البرتغالية",
          ru: "الروسية",
          ar: "عربي",
          payment_method: " طريقة الدفع",
          filling_details: " تفاصيل التعبئة",
          trx_id: "معرّف المعاملة",
          time_remain: " الوقت المتبقي:",
          hide: " إخفاء",
          show: " إظهار",
          product_description: "وصف المنتج",
          order_value: " قيمة الطلب",
          fee_102: " رسوم بنكية",
          fee_101: "ضريبة",
          fee_100: " رسوم أخرى",
          discount: " خصم",
          total: " المجموع",
          confirm: " تأكيد",
          return: " العودة",
          privacy_policy_error: " يجب أن تقرأ وتقبل شروط الاستخدام وسياسة الخصوصية لإكمال عملية الشراء",
          privacy_policy1: " لقد قرأت ووافقت على",
          privacy_policy2: " شروط الاستخدام",
          privacy_policy3: " و",
          privacy_policy4: " سياسة الخصوصية",
          privacy_policy5: " Pagsmile من ",
          pci_notice: " PCI DSS جميع المعاملات مضمونة بأمان ومتوافقة مع بروتوكول",
          "payment.redirect.notice1": "ستتم إعادة توجيهك إلى صفحة التاجر خلال {{key}} ثانية",
          "payment.redirect.notice2": "انقر ",
          "payment.redirect.notice3": "هنا ",
          "payment.redirect.notice4": "للإلغاء",
          back_shop: " العودة إلى التسوق",
          invalid_name: " اسم غير صالح",
          cardholder_name: " الاسم الكامل لحامل البطاقة",
          exp_month: "شهر نهاية صلاحية",
          invalid_month: " اختر شهرًا",
          exp_year: "سنة نهاية صلاحية ",
          invalid_year: " اختر سنة",
          invalid_cvv: "غير صالح CVC",
          phone: " رقم الهاتف المحمول",
          invalid_phone: " رقم الهاتف غير صالح",
          email: " البريد الإلكتروني",
          invalid_email: " بريد إلكتروني غير صالح",
          address_billing: "عنوان إرسال الفواتير:",
          country: " البلد",
          invalid_country: " اختر البلد",
          state: "مقاطعة",
          invalid_state: " اختر مقاطعة ",
          city: "المدينة",
          invalid_city: " اختر المدينة",
          street: " شارع",
          invalid_street: " شارع غير صالح",
          number: "رقم",
          invalid_number: " رقم غير صالح",
          select_country: " الرجاء تحديد بلد أولاً",
          postal_code: " الرمز البريدي",
          invalid_postal_code: " رمز بريدي غير صالح",
          address_delivery: ": عنوان التسليم",
          address_same: " نفس عنوان إرسال الفواتير",
          details: " التفاصيل",
          card_no: "رقم البطاقة",
          invalid_card_no: "رقم بطاقة غير صالحة",
          expiry_month: "شهر انتهاء الصلاحية",
          invalid_month2: "شهر غير صالح",
          expiry_year: "سنة انتهاء الصلاحية",
          invalid_year2: "سنة غير صالحة",
          credit_debit_card: "بطاقة الائتمان / الخصم",
          okay: "!أوكي",
          network_err: "خطأ في الشبكة ، يرجى المحاولة مرة أخرى",
          fail_try_agin: "حدث خطأ ما ، يرجى المحاولة مرة أخرى لاحقًا",
          fail_change_method: "حدث خطأ ما. يُرجى استخدام طريقة دفع أخرى",
          fail_notice: "من الممكن أن تكون المعلومات خاطئة ، حاول مرة أخرى أو تواصل معنا",
          payment_fail: "فشل الدفع",
          failed: "فشل",
          success_result: "نجاح",
          success_notice: " Pagsmile  لقد دفعت الطلب بنجاح. الرجاء الرجوع إلى صفحة البائع! شكرًا لك على استخدام",
          cancelled: "Cancelled",
          order_timeout: " انتهى الحد الزمني لدفعتك ، يرجى الرجوع إلى صفحة البائع لتقديم طلب جديد",
          processing: "معالجة",
          processing_notice: "! Pagsmile المعاملة قيد المعالجة، وستكون سريعة. شكرًا لك على استخدام",
          success: "تم الدفع بنجاح",
          refund_verifying: "استرداد الأموال",
          refund_processing: "معالجة استرداد الأموال",
          risk_control: "التحكم في المخاطر",
          refunded: "استرداد",
          partial_refund: "تم رد الأموال جزئيًا",
          chargeback: " رد المبالغ المدفوعة",
          chargeback_reversed: "تم رد المبالغ المدفوعة",
          dispute: "نزاع",
          dispute_reversed: "تم النزاع",
          timeout: "انتهت مهلة المعاملة",
          payment_refuse: "دفعة مرفوضة",
          refund_reverse: "تم استرداد الأموال",
          refund_refuse: "تم رفض رد الأموال",
          pay_close: "مغلق",
          fail_sec: "تم إغلاق هذا الطلب. يرجى إرساله مرة أخرى من أجل تأمين معاملتك",
          edit_address: "تحرير العنوان",
          20000: "الخدمة غير متوفرة حاليًا",
          40000: "فشلت المعاملة",
          40002: "فشلت المعاملة",
          40012: "الطلب غير موجود",
          40013: "انتهى الأمر",
          40014: "معلومات الطلب غير متسقة",
          40015: "تجاوز الحد الأقصى للمبلغ (بما في ذلك الرسوم)",
          40101: "مهلة الطلب",
          40201: "فشل التحميل",
          40301: "البنك مشغول",
          "SYS-0001": "تم رفض دفعتك. اختر طريقة دفع أخرى. نوصي بطرق الدفع نقدًا",
          "SYS-0002": "وقت مستقطع",
          "SYS-0003": "مبلغ المعاملة غير صالح",
          "SYS-0004": "يجب على الدافع تقديم بريد إلكتروني صالح",
          "CC-0001": "مستخدمون غير صالحين للمشغلين",
          "CC-0002": "رمز البطاقة غير صالح",
          "CC-0003": "الطوارئ المعلقة",
          "CC-0004": "دليل المراجعة المعلق",
          "CC-0005": "رقم البطاقة المرفوض والمعبأ غير صالح",
          "CC-0006": "تاريخ مرفوض وملء غير صالح",
          "CC-0007": "مرفوضة، وملأت أخرى سيئة",
          "CC-0008": "رمز أمان مرفوض ومملوء بشكل سيئ مرفوضة،",
          "CC-0009": " مرفوضة، في القائمة السوداء",
          "CC-0010": " مرفوض، دعوة للحصول على إذن",
          "CC-0011": "مرفوضة، البطاقة معطلة",
          "CC-0012": " مرفوض، خطأ في البطاقة ",
          "CC-0013": "دفع مكرر مرفوض",
          "CC-0014": "مرفوضة ، عالية الخطورة",
          "CC-0015": "مبلغ مرفوض وغير كافٍ",
          "CC-0016": "أقساط مرفوضة وغير صالحة",
          "CC-0017": "مرفوضة ، الحد الأقصى من المحاولات",
          "CC-0018": " مرفوض ، سبب آخر",
          70001: "نحن غير قادرين على معالجة دفعتك",
          refresh_notice: " سيتم تحديث الصفحة تلقائيًا في غضون 30 ثانية ، وإلا يرجى النقر فوق الزر أدناه",
          refresh: "تحديث",
          fail_query: "هل تريد المحاولة مرة أخرى؟",
          yes: "نعم",
          help_me: " المساعدة ",
          source: "موقع الويب أو اسم التطبيق الذي اشتريت منه المنتج",
          timed_out: "انتهت مهلة طلب المعاملة",
          no_channel: "لا توجد قناة ، يرجى الاتصال بالتاجر",
          "70001-10001": "CPF الذي تم إدخاله غير صالح ، أدخل واحدًا آخر",
          "70001-10002": "هذه الصفقة لا يمكن أن تتم معالجتها. اتصل بدعم العملاء",
          "70001-10003": "هذه الصفقة لا يمكن أن تتم معالجتها. اتصل بدعم العملاء",
          "70001-10004": "هذه الصفقة لا يمكن أن تتم معالجتها. اتصل بدعم العملاء",
          "70001-20001": "تجاوز حد المعاملة",
          "70001-99999": "هذه الصفقة لا يمكن أن تتم معالجتها. اتصل بدعم العملاء",
        },
      },
    },
  });

export default i18n;
