import React, { useEffect, useState } from "react";
import "./TaxId.scss";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Correct from "../../img/correct.jpg";
import { formatTaxId } from "../../utils/formats";
import { validateTaxId } from "../../utils/validations";
import { countryCode2TaxIdType } from "../../utils/configurations";
import { checkTaxId } from "../../utils/validations";
import { connect } from "react-redux";
import TextFieldCustom from "./TextFieldCustom";

function TaxId({ form, setForm, mobile, setFocusOnInput = () => {}, disableIfData = false, ...props }) {
  const { t } = useTranslation();
  const tradeInfo = props.tradeInfo;
  const region = tradeInfo?.app_info?.region;

  const [disable, setDisable] = useState(false);
  const [idTypes, setIdTypes] = useState(countryCode2TaxIdType[region]);

  useEffect(() => {
    if (!form.taxId.checked && form.idType) {
      // console.log("form.taxId.checked: ", form.taxId.checked);
      let taxId = "";
      let idType = "";

      if (validateTaxId(tradeInfo?.user?.identify?.number, region, tradeInfo?.user?.identify?.type)) {
        taxId = tradeInfo?.user?.identify?.number;
        idType = tradeInfo?.user?.identify?.type;
        // console.log(`Id from tradeInfo >> taxId: ${taxId}, idtype: ${idType}`);
      } else if (validateTaxId(localStorage?.getItem("__frm_tax_id"), region, localStorage?.getItem("__frm_id_type"))) {
        taxId = localStorage?.getItem("__frm_tax_id");
        idType = localStorage?.getItem("__frm_id_type");
        // console.log(`Id from storage >> taxId: ${taxId}, idtype: ${idType}`);
      }

      if (taxId && idType && idTypes?.indexOf(idType) >= 0) {
        disableIfData && setDisable(true);
        // console.log("idType set 2");
        setForm((prev) => {
          return {
            ...prev,
            idType: idType,
            taxId: {
              ...prev.taxId,
              checked: true,
              valid: true,
              value: formatTaxId(taxId, region, idType),
            },
          };
        });
      }
    }
  }, [idTypes, !!form.idType]);

  useEffect(() => {
    if (props.method === "BankTransfer" && props.payChannel === "Localpayment" && region === "ARG") {
      const newIdTypes = countryCode2TaxIdType["ARG"].filter((item) => item !== "DNI");
      setIdTypes(newIdTypes);
      setForm((prev) => ({
        ...prev,
        idType: newIdTypes[0],
        taxId: { ...prev.taxId, value: "", valid: true, checked: false },
      }));
    } else if (
      (props.method === "BankTransfer" || props.method === "Cash") &&
      props.payChannel === "Monnet" &&
      region === "ECU"
    ) {
      const newIdTypes = ["CI"];
      setIdTypes(newIdTypes);
      setForm((prev) => ({
        ...prev,
        idType: newIdTypes[0],
        taxId: { ...prev.taxId, value: "", valid: true, checked: false },
      }));
    }
  }, [props.method, props.payChannel]);

  return (
    <>
      <TextFieldCustom
        fullWidth
        id={mobile ? "inputform-taxid-mob" : "inputform-taxid"}
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        inputProps={{ autocomplete: "new-password", "ga-data": "351" }}
        size="small"
        error={!form.taxId.valid}
        helperText={!form.taxId.valid && t("invalid_TIN")}
        className="homeMobile__filling__content__input"
        label="ID"
        variant="outlined"
        value={form.taxId.value}
        disabled={disable}
        onChange={(event) => {
          let value = formatTaxId(event.target.value, region, form.idType);
          setForm((prev) => {
            return {
              ...prev,
              taxId: { ...prev.taxId, value: value },
            };
          });
        }}
        onBlur={() => {
          checkTaxId({ form: form, setForm: setForm, region: region });
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Select
                className="taxId__typeSelect"
                variant="standard"
                value={form.idType}
                disabled={disable}
                onChange={(event) => {
                  setForm((prev) => {
                    return {
                      ...prev,
                      idType: event.target.value,
                      taxId: { ...prev.taxId, value: "", valid: true, checked: false },
                    };
                  });
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                {idTypes?.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={Correct}
                alt=""
                style={{
                  display: form.taxId.valid && form.taxId.checked ? "block" : "none",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    method: state.method,
    payChannel: state.payChannel,
    tradeInfo: state.tradeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TaxId);
