import React from "react";
import useMethodConfig from "./useMethodConfig";
import { useSelector } from "react-redux";
import "./Home.scss";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import getParameterByName from "../../utils/queryUrl";

const useStyles = makeStyles({
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px",
  },
  button: (props) => ({
    backgroundColor: props.button?.color,
    "&:hover": {
      backgroundColor: props.button?.color,
    },
    font: props.button?.font,
    textTransform: "none",
    border: 0,
    borderRadius: 3,
    color: "white",
    padding: "7px 22px",
  }),
});

function Home() {
  const { t } = useTranslation();
  const { tradeInfo, paymentInfo, styles } = useSelector((state) => state);
  const methodConfig = useMethodConfig();
  const channelConfig =
    methodConfig[tradeInfo?.method]?.channels?.commanConfig?.channelConfig ||
    methodConfig[tradeInfo?.method]?.channels[tradeInfo?.channel]?.channelConfig;
  const classes = useStyles(styles);
  return (
    <div className="home">
      {channelConfig?.jsxForm}
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => {
            channelConfig?.handleRequest?.();
          }}
        >
          {t("confirm")}
        </Button>
      </div>
    </div>
  );
}

export default Home;
